import React, { useState, Component, Fragment, useEffect } from 'react'
import withRoot from '../../../withRoot'
import { withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container';
import { fetchTeamLSI } from 'common/helpers/lsi'
import GraphContainer from './GraphContainer'
import moment from 'moment'
import { Redirect } from 'react-router'
import TeamTable from 'team/TeamTable';
import CircularProgress from '@material-ui/core/CircularProgress'
import SimpleModal from 'common/components/SimpleModal'
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid'
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { getRandomQuote } from 'common/helpers/quotes';

const styles = theme => ({
  loading: {
    margin: '0 auto'
  },
  teamDash: {
    textAlign: 'center',
    marginTop: '50px'
  },
  learn: {
    display: 'block',
    float: 'right',
    marginTop: "20%"
  },
  close: {
    marginLeft: "70%",
    marginBottom: "5%"
  },
  title: {
    margin: "1rem"
  },
  footerQuote: {
    fontSize: "large",
    margin: "2rem"
  },
  graphContainer: {
    margin: "2rem"
  }
})

const TeamContainer = (props) => {

  const {classes} = props
  const [lsiHistory, setLsiHistory] = useState(null)
  const [team, setTeam] = useState(0)
  const [quote, _setQuote] = useState(getRandomQuote())
  const [teamData, setTeamData] = useState(null)
  const [timeRange, setTimeRange] = useState({})
  const [openTeamModal, setOpenTeamModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [timePeriod, setTimePeriod] = useState("YEARLY")
  const [redirect, setRedirect] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      let response = null;
      try {
	response = await fetchTeamLSI()
      } catch (e) {
	console.log("Here is the error for teams: ", e)
	// TODO: To be changed when teams is deployed
	setOpenTeamModal(true);
	return
      }

      if (!response || !response.data)
	return

      setLsiHistory(response.data.team_lsi)
    }
    fetchData()
  }, [])

  useEffect(() => {
    if(!lsiHistory)
      return;
    var lsi = lsiHistory.team_lsi[team]
    if(lsi){
      setTeamData(lsi)
      setTimeRange([moment(lsi.lsiStartDate).subtract(1, "days").unix(), moment(lsi.lsiEndDate).unix()])
    }
    setLoading(false)
  }, [lsiHistory, team])

  return(
      <Container maxWidth="md" className = {classes.teamDash}>
      <SimpleModal title={
          <React.Fragment>
          Teams
          <CloseIcon className = {classes.close} onClick = {() => setOpenTeamModal(false)} />
          </React.Fragment>
      }
    description={"You haven't teamed up yet. Invite your friends to lmbk"} open={openTeamModal} setOpen={setOpenTeamModal}>
      <Grid>
      <Link href="https://www.lmbk.io/teams" target="_blank" rel="noopener" className={classes.learn} >
      LEARN MORE
    </Link>
      </Grid>
      </SimpleModal>
      {redirect}
    {  !loading ?
       <React.Fragment>
       {
         teamData ?
           <div>
	   <Grid className={classes.title}>
	   <Typography variant="h4">
	   { "Team " + teamData.name }
	 </Typography>
	   </Grid>
	   <Grid className={classes.graphContainer}>
           <GraphContainer timePeriod={timePeriod} teamData={teamData} timeRange={timeRange}/>
	   </Grid>
           <TeamTable setTeam = {setTeam} lsiHistory = {lsiHistory} />
           </div>
           :
           <div>No Team Data</div>
       }
       </React.Fragment>
       :
       <CircularProgress className={classes.loading} />
    }
      <p className={classes.footerQuote}>{quote}</p>
      </Container>
  )
}

export default withRoot(withStyles(styles, {withTheme: true})(TeamContainer))
