import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withRoot from '../../../withRoot'
import config from 'app/config'


// Material Components
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


//internal components
import { updateUser } from 'common/helpers/account'
import { user_id } from 'common/helpers/storage';
import { fetchUserDetail } from 'common/helpers/account'

const styles = theme => ({
  label: {
    color: "#9e9e9e",
  },
  profile_pic: {
    height: "100px",
    width: "100px"
  },

  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
});



const useStyles = makeStyles({

  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
});



class AccountSettings extends Component {
    constructor(props) {
        super(props)
    }

    state = {
      first_name: '',
      last_name: '',
      username: '',
      email: '',
      team: '',
      role: '',
      loading: true,
      profile_pic: null,
    }


    item = (label, value) => {
      return(
        <Grid item xs={12} md={4}>
          <Typography className={this.props.classes.label}>
            {label}
          </Typography>
          <Typography className={this.props.classes.value}>
            { value }
          </Typography>
          </Grid>
      )
    }

    static propTypes = {
      classes: PropTypes.object.isRequired,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      username: PropTypes.string,
      email: PropTypes.string,
      team: PropTypes.string,
      role: PropTypes.string,
      profilePic: PropTypes.object
    }

    componentDidMount = () => {

      return fetchUserDetail(user_id())
      .then(res => {
          if (res) {
            this.setState({
              firstName: res.first_name || '',
              lastName: res.last_name || '',
              username: res.username || '',
              email: res.email || '',
              // role: res.role || '',
              // team: res.team || '',
              loading: false,
              // date_joined: new Date(res.date_joined) || '',
              profilePic: res.profile_pic || null

            })
          }
      })
      .catch(e => console.log('fetchInfoError:', e))
    }

    avatarUploadHandler = () => {
      let profilePic = event.target.files[0]
      const fd = new FormData()
      fd.append('profile_pic', profilePic, profilePic.name)

      updateUser(user_id(), fd).then((res) => {
        this.setState({profilePic: res.data.profile_pic})
      }).catch(e => console.log("Image Upload Error: ", e))
    }


    render() {
        const { classes } = this.props
        const { firstName, lastName, username, email, role, team, profilePic} = this.state

        return (

          <React.Fragment>
          { (!this.state.loading) ?
            <Grid container spacing={3}>

            <Grid item xs={12} md = {2}>
                <Avatar
                style={{
                  'margin': 10,
                  'width': 100,
                  'height': 100,}}>
                  <img  alt={this.state.username} src={ `${profilePic}` } className = {classes.profile_pic} />
                </Avatar>
                <input
                  style={{display: 'none'}}
                  type="file"
                  onChange={this.avatarUploadHandler}
                  ref={ imageInput => this.imageInput = imageInput}
                />

                <Grid>
                  <Button onClick={() => this.imageInput.click()}>Choose File</Button>
                </Grid>
              </Grid>


              <Grid item xs={12} md = {5}>
                {this.item('First Name', firstName)}
                {this.item('Last Name', lastName)}
                {this.item('Email', email)}
              </Grid>
              <Grid item xs={12} md = {5}>

                {this.item('Username', username)}
                {this.item('Role', role || 'No Role Listed')}
                {this.item('Team', team || 'No Team Listed')}
              </Grid>

          </Grid>

          :

          <CircularProgress  />

          }
          </React.Fragment>





        )
    }
  }

export default withRoot(withStyles(styles, { withTheme: true })(AccountSettings))
