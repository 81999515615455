import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import withRoot from '../../../withRoot'
import Routes from '../../../routes'
import { withStyles } from '@material-ui/core/styles'
import { checkAuth } from 'common/helpers/auth'
import { token } from 'common/helpers/storage'
import { fetchAuth, logoutAuth } from 'core/actions/auth'
import { ConnectedRouter } from 'connected-react-router'
import classNames from 'classnames'
import Sidebar from 'common/components/Sidebar'
import { NotificationDisplay } from 'common/context/Notifications'
import { UserProvider } from 'common/context/User'
// Material Colors
import grey from '@material-ui/core/colors/grey'

const drawerWidth = 240


const styles = theme => ({
    root: {
        flexGrow: 1,
        height: '100%',
        zIndex: theme.zIndex.drawer + 1,
        overflow: 'auto',
        position: 'abosolute',
        display: 'flex',
        transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 24,
    },
    hide: {
        display: 'none',
    },
    lmkbLogo: {
        path: {
            '.cls-1': {
                fill: '#64ddf9'
            },
            '.cls-2': {
                fill: '#00ffa3'
            }
        }
    },
    drawerPaper: {
        backgroundColor: grey[800],
        position: 'absolute',
        whiteSpace: 'nowrap',
        height: '100%',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        height: '100%',
        [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
        },
    },

})


class AppContainer extends Component {
    constructor(props) {
        super(props)
    }

    static propTypes = {
        auth: PropTypes.object,
        history: PropTypes.object
    }

    state = {
        authRedirect: null,
        open: false
    }

    handleDrawerOpen = () => {
        this.setState({ open: true })
    }

    handleDrawerClose = () => {
        this.setState({ open: false })
    }

    componentWillUpdate = (nextState, nextProps) => {
        if (this.state.authRedirect !== nextState.authRedirect) return true
        return false
    }

    componentWillMount = () => {
        return checkAuth()
        .then(res => {
            this.setState({authRedirect: res})
        })
    }

    render() {
        const { classes } = this.props
        const isAuthed = token() ? true : false
        return (
            <ConnectedRouter history={this.props.history}>
                <UserProvider>
                    <Grid container className={classes.root}>
                        <NotificationDisplay />
                        <Grid item >
                            <Sidebar isAuthed = {isAuthed} />
                        </Grid>
                        <Grid item xs className={classNames(this.state.open && classes.appShift)}>
                            <main className={classNames(classes.content)}>
                                < Routes />
                            </main>
                        </Grid>
                    </Grid>
                </UserProvider>
            </ConnectedRouter>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { auth } = state

    return {
        auth: auth.get('data'),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(
            {
                fetchAuth,
                logoutAuth,
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRoot(withStyles(styles, { withTheme: true })(AppContainer)))
