import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import 'react-vis/dist/style.css'
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalBarSeries,
  Hint,
  makeWidthFlexible
} from 'react-vis'



const FlexibleXYPlot = makeWidthFlexible(XYPlot)

const DAILY_GRAPH_TYPE = 'DAILY'
const WEEKLY_GRAPH_TYPE = 'WEEKLY'
const MONTHLY_GRAPH_TYPE = 'MONTHLY'
const YEARLY_GRAPH_TYPE = 'YEARLY'


const hourTickFormat = tick => {
  return moment.unix(tick).format('MMM Do HH:mm')
}

const dayTickFormat = tick => {
  return moment.unix(tick).format('MMM Do HH:mm')
}

const monthTickFormat = tick => {
  return moment.unix(tick).format('MMM Do')
}

class LSIGraph extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentValue: null
    }
    this._rememberValue = this._rememberValue.bind(this)
    this._forgetValue = this._forgetValue.bind(this)
  }

  _rememberValue(currentValue) {
    this.setState({currentValue})
  }

  _forgetValue() {
    this.setState({
      currentValue: null
    })
  }

  static propTypes = {
    timePeriod: PropTypes.oneOf([DAILY_GRAPH_TYPE, WEEKLY_GRAPH_TYPE, MONTHLY_GRAPH_TYPE, YEARLY_GRAPH_TYPE])
  }

  graphTypes = {
    daily: DAILY_GRAPH_TYPE,
    weekly: WEEKLY_GRAPH_TYPE,
    monthly: MONTHLY_GRAPH_TYPE,
    yearly: YEARLY_GRAPH_TYPE
  }

  getTickCount = () => {
    const { timePeriod } = this.props

    switch (timePeriod) {
      case DAILY_GRAPH_TYPE:
        return 13
      case WEEKLY_GRAPH_TYPE:
        return 8
      case MONTHLY_GRAPH_TYPE:
        return 30
      case YEARLY_GRAPH_TYPE:
        return 13
      default:
        return null
    }
  }

  getTickFormat() {
    const { timePeriod } = this.props

    switch (timePeriod) {
      case DAILY_GRAPH_TYPE:
        return hourTickFormat
      case WEEKLY_GRAPH_TYPE:
        return dayTickFormat
      case MONTHLY_GRAPH_TYPE:
        return monthTickFormat
      case YEARLY_GRAPH_TYPE:
        return monthTickFormat
      default:
        return null
    }
  }

  getHintPoints(func) {
    return (points) => {
      return [
        {title: 'Stress Level', value: points.y},
        {title: 'Time', value: func(points.x)}
      ]
    }
  }

  render() {
    const { lsiHistory, timeRange} = this.props
    const { currentValue } = this.state
    const tickFormat = this.getTickFormat()
    const hintPointsFormat = this.getHintPoints(tickFormat)
    const tickCount = this.getTickCount()

    return (
      <div>
        <FlexibleXYPlot height={300} margin={{bottom: 80, left:60}} xType="linear" xDomain={timeRange} yDomain={[0, 10]}>
          <XAxis title={'Time of day'} tickFormat={x => tickFormat(x)} tickTotal={tickCount} tickLabelAngle={-45}/>
          <YAxis title={'stress'}/>
          {currentValue ?
            <Hint value={currentValue} format={hintPointsFormat}/>:
            null
          }
          <VerticalBarSeries
            data={lsiHistory}
            color={'rgb(100, 221, 249)'}
            onValueMouseOver={this._rememberValue}
            onValueMouseOut={this._forgetValue}
            />
        </FlexibleXYPlot>
      </div>
    )
  }
}

export {
  LSIGraph,
  DAILY_GRAPH_TYPE,
  WEEKLY_GRAPH_TYPE,
  MONTHLY_GRAPH_TYPE,
  YEARLY_GRAPH_TYPE
}
