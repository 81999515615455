import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import withRoot from '../../../withRoot'
import { withStyles } from '@material-ui/core/styles'
import { isAuthenticated, isEmailActivated } from 'common/helpers/auth'
import { fetchDeviceActive } from 'common/helpers/device'
import { Redirect } from 'react-router'

// Material Components
import RegistrationStepper from 'core/components/RegistrationStepper'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = theme => ({
    root: {
        marginTop: '32px'
    },
    progress: {
        marginTop: '280px',
    }
})

class RegistrationContainer extends Component {
    constructor(props) {
        super(props)
    }

    state = {
        isAuthedNoDevice: false,
        isDeviceVerified: false,
        isEmailActivated: false,
        loading: true
    }

    isEmailActivated = () => {
        return isEmailActivated().then(res => { 
            if(res){
                return({isEmailActivated: res.data.user_settings.email_activated})
            }else{
                return false
            }
        })
    }

    isAuthedNoDevice = () => {
        const isAuthed = isAuthenticated()
        return fetchDeviceActive()
        .then(res => {
            if (isAuthed && !res) {
                return({isAuthedNoDevice: true, isDeviceVerified: false})
            }
            else if (isAuthed && res) {
                return({ isAuthedNoDevice: false, isDeviceVerified: true })
            }
            else {
                return({isAuthedNoDevice: false, isDeviceVerified: false})
            }
        })
        .catch(e => {
            return({isAuthedNoDevice: false, isDeviceVerified: false})
        })
    }

    initialStepperState = async () => {
        let dAuth = await this.isAuthedNoDevice()
        let eAct = await this.isEmailActivated()
        this.setState({
            loading: false,
            isAuthedNoDevice: dAuth.isAuthedNoDevice,
            isDeviceVerified: dAuth.isDeviceVerified,
            isEmailActivated: eAct.isEmailActivated
        })
    }

    componentWillMount = () => {
        this.initialStepperState()
    }
    
    componentDidMount () {
        const script = document.createElement("script");
    
        script.src = "//js.hs-scripts.com/6691217.js";
        script.async = true;
        script.id = "hs-script-loader";
    
        document.body.appendChild(script);
    }

    getContentsOrRedirect = () => {
        const { classes } = this.props
        if (this.state.isDeviceVerified) {
            return (
                <Redirect to="/cortex" />
            )
        }
        else {
            return (
                <Grid container alignItems={'center'} justify={'center'} direction={'column'}>
                    {this.state.loading
                        ? <CircularProgress className={classes.progress} color="primary" size={80} thickness={8} />
                        : <RegistrationStepper
                            isEmailActivated={this.state.isEmailActivated}
                            isSettingDevice={this.state.isAuthedNoDevice}
                            isDeviceVerified={this.state.isDeviceVerified}/>
                    }
                    <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/6691217.js"></script>
                </Grid>
            )
        }
    }
    render() {
        return (
            this.getContentsOrRedirect()
        )
    }
}

export default withRoot(withStyles(styles, { withTheme: true })(RegistrationContainer))
