import config from 'app/config'
import { token } from 'common/helpers/storage'
import api from 'common/helpers/api'

const _getGarminAccess = () => {
    api.configure(token())
    const data = {}
    const url = `${config.API_URL}/garmin/connect/`
    return api.post(url, data)
    .then((res) => {
        window.open(res.data.request_authorization_url, '_blank')
    })
    .catch((e) => {
        console.log(e)
    })
}


// request an access key and url to verify garmin account
export const fetchGarminAccessKey  = () => {
    return _getGarminAccess()
}

export const syncData = () => {
    return api.post(`${config.API_URL}/garmin/sync/`)
}