import config from 'app/config'
import api from 'common/helpers/api'
import moment from 'moment'

export const getTeams = () => {
    return api.get(`${config.API_URL}/team/`)
}

export const callCreateTeam = (name) => {
    return api.post(`${config.API_URL}/team/`, {name: name})

}

export const setPermissions = (value) => {
    return api.patch(`${config.API_URL}/team/user/`, value)
}

export const createShareURL = (team_id, token) => {
    return `${config.APP_URL}/confirmation?token=${token}&team_id=${team_id}`
}

export const deleteTeam = (team_id) => {
    return api.delete(`${config.API_URL}/team/${team_id}/`)
}

export const getTeam = (team_id) => {
    return api.get(`${config.API_URL}/team/${team_id}/`)
}

export const addUser = (team_id, token) => {
    return api.post(`${config.API_URL}/team/user/`, {team_id: team_id, token: token })
}