import React, { useReducer } from 'react'



export const UserSettingsContext = React.createContext({});

export const UserSettingsProvider = props => {

    let user_settings = {
        email_activated: false,
        slack_enabled: false,
        garmin_enabled: false, 
        slack_integrated: false,
        github_enabled: false,
        github_integrated: false,
        team_color: '#000'
    }

    const userSettingsReducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE':
                return {[action.payload.key]: action.payload.value};
        }
    }

    const [state, dispatch] = useReducer(userSettingsReducer, user_settings);


    return(
        <UserSettingsContext.Provider value = { { state: state, dispatch: dispatch } }>
            { props.children }
        </UserSettingsContext.Provider>
    )
}