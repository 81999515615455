import config from 'app/config'
import api from 'common/helpers/api'

export const postReset = (email) => {
    const url = `${config.API_URL}/reset/`
    return api.post(url, {email: email})
}

export const postUpdate = (password, token) => {
    const url = `${config.API_URL}/update/`
    return api.post(url, {password: password, token: token})
}