import React from 'react'
import withRoot from 'root/withRoot'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

const styles = theme => ({
    container: {
        marginTop: '10%'
    },
    typography: {
        color: '#00ffa3',
    },
    header: {
        backgroundColor: theme.palette.primary.main,
        width: '100%',
        padding: '8px',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px'
    }
})

const InputCard = props => {
    const {classes, header, body} = props
    return(
        <Grid container alignItems={'center'} justify={'center'} direction={'column'} className={classes.container}>
            <Grid item xs={12}>
                <Paper className={classes.root} elevation={4}>
                    <Grid className={classes.header} direction="column">
                        {header}
                    </Grid>
                    <Grid>
                        {body}
                    </Grid>                    
                </Paper>
            </Grid>
        </Grid>
    )
}

export default withRoot(withStyles(styles, { withTheme: true })(InputCard))
