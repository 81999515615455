import React from 'react'
import withRoot from '../../withRoot'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TeamRow from 'team/TeamRow'

const styles = ({
    root: {
      width: '100%',
      overflowX: 'auto',
    },
    table: {
      minWidth: 650,
    },
  });

const TeamTable = (props) => {
    const { classes, setTeam, lsiHistory } = props
    return(
        <Paper className={classes.root}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Team Name</TableCell>
                        <TableCell>Team Count</TableCell>
                        <TableCell>Team Stress Average</TableCell>
                        <TableCell>Switch Graph</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        lsiHistory['team_lsi'].map((team, i) => <TeamRow key = {i} index = {i} setTeam = {setTeam} team = {team} />)
                    }
                    
                </TableBody>
            </Table>
          </Paper>

    )
}

export default withRoot(withStyles(styles, {withTheme: true})(TeamTable))
