import config from 'app/config'
import api from 'common/helpers/api'

export const integrateSlack = () => {
    const url = `${config.API_URL}/slack/activation/`
    return api.get(url)
}

export const toggleSlackCall = () => {
    const url = `${config.API_URL}/slack/activation/`
    return api.post(url)
}