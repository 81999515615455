import config from 'app/config'
import api from 'common/helpers/api'

export const integrateGithub = () => {
    const url = `${config.API_URL}/github/activation/`
    return api.get(url)
}

export const toggleGithubCall = () => {
    const url = `${config.API_URL}/github/activation/`
    return api.post(url)
}