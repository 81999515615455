import React, { useState, useContext }from 'react'
import { withStyles } from '@material-ui/core/styles'
import withRoot from '../../../withRoot'
import { postUpdate } from 'core/reset/reset';
import queryString from 'query-string';
import InputCard  from 'common/components/InputCard'
import Grid from '@material-ui/core/Grid'
import { Redirect } from 'react-router'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link';


import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import { Input } from '@material-ui/core';
import { NotificationContext } from 'common/context/Notifications';

const styles = theme => ({
    root:{
        display: 'flex',
        flexDirection: 'column',
        margin: '32px',
    },
    input: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(3),
        width: 200,
    },
    header: {
      marginLeft: "2%"
    },
    button: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginTop: '32px',
        marginBottom: theme.spacing(1),
        '&:hover': {
            backgroundColor: theme.palette.secondary.main
        }
    },
})

function UpdatePasswordContainer (props) {
    const {classes} = props
    const [password, setPassword] = useState("");
    const [passwordComp, setPasswordComp] = useState("");
    const [redirect, setRedirect] = useState(null)
    const notification = useContext(NotificationContext)
    let params = queryString.parse(props.location.search)

    function handleChange (e) {
        setPassword(e.target.value)
    }

	function handleChangeComp (e) {
        setPasswordComp(e.target.value)
    }

	async function handleSubmit(e){
        if(password != passwordComp){
            notification.notify("The passwords that you've entered don't match")
            return;
        }

        try{
            console.log(params)
            let response = await postUpdate(password, params.token);
            notification.notify(response.data)
            setRedirect(() => <Redirect to="/"/>)
          }catch(err){
            notification.notify(err.response.data)
          }
    }

    return(
        <React.Fragment>
            {redirect}

            <InputCard

                header = {
                    <Typography variant="h5" component="h3" align="center">Reset password</Typography>
                }
                body = {
                    <React.Fragment>
                        <Grid className={classes.root}>
                            <Grid>

                                <TextField
                                    className={classes.input}
                                    onChange={handleChange}
                                    value={password}
                                    type="password"
                                    placeholder="Password"
                                    margin="normal"

                                />

                            </Grid>
                            <Grid>

                                <TextField
                                    className={classes.input}
                                    onChange={handleChangeComp}
                                    value={passwordComp}
                                    type="password"
                                    placeholder="Confirm Password"
                                    margin="normal"

                                />

                            </Grid>

                            <Button variant="contained" color="primary" className={classes.button} onClick = {handleSubmit} >Submit</Button>
                        </Grid>
                    </React.Fragment>
                }
            />
        </React.Fragment>

    );
}


export default withRoot(withStyles(styles, { withTheme: true })(UpdatePasswordContainer))
