import React, { useState, useEffect } from 'react'

// material
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid'
import { toggleGarminCall } from 'core/integrations/garmin'
import { integrateSlack, toggleSlackCall } from 'core/integrations/slack'
import { integrateGithub, toggleGithubCall } from 'core/integrations/github'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'

//internal components
import { user_id } from 'common/helpers/storage';
import { fetchUserDetail } from 'common/helpers/account'

export default function AccountSettingsApps () {

    const [loading, setLoading] = useState(true)
    const [slackEnabled, setSlackEnabled] = useState(false)
    const [slackIntegrated, setSlackIntegrated] = useState(false)
    const [githubEnabled, setGithubEnabled] = useState(false)
    const [githubIntegrated, setGithubIntegrated] = useState(false)
    const [garminEnabled, setGarminEnabled] = useState(false)

    useEffect(() => {
      fetchUserDetail(user_id())
      .then(res => {
        setSlackEnabled(res.user_settings.slack_enabled)
        setSlackIntegrated(res.user_settings.slack_integrated)
        setGithubEnabled(res.user_settings.github_enabled)
        setGithubIntegrated(res.user_settings.github_integrated)

        setGarminEnabled(res.user_settings.garmin_enabled)
        setLoading(false)
      })
    }, [])


    const toggleSlack = () => {
      toggleSlackCall().then((res) => {
        setSlackEnabled(res.data)
      })
    }

    const toggleGithub = () => {
      toggleGithubCall().then((res) => {
        setGithubEnabled(res.data)
      })
    }

    const toggleGarmin = () => {
      toggleGarminCall().then((res) => {
        setGarminEnabled(res.data)
      })
    }

    return(
        <Grid container alignItems="flex-start" direction="column" style={{paddingTop:"1%"}}>
        
        { !loading ?   
            <FormControl component="fieldset">
                  <FormGroup aria-label="position">
                    <FormControlLabel
                      value="start"
                      control={<Switch color="primary" checked = { garminEnabled } onClick = { toggleGarmin } />}
                      label="Garmin"
                      labelPlacement="start"
                    />
                    
                    {
                      slackIntegrated ?
                        <FormControlLabel
                          value="bottom"
                          control={<Switch color="primary" checked = {slackEnabled} onClick = { toggleSlack } />}
                          label="Slack"
                          labelPlacement="start"
                          style={{paddingTop:"15%"}}
                        /> 
                        :
                        <Button onClick = {() => integrateSlack().then((res) => window.open(res.data, "_blank") )}>Integrate With Slack</Button>
                    }

                    {
                      githubIntegrated ?
                        <FormControlLabel
                          value="bottom"
                          control={<Switch color="primary" checked = {githubEnabled} onClick = { toggleGithub } />}
                          label="Github"
                          labelPlacement="start"
                          style={{paddingTop:"15%"}}
                        /> 
                        :
                        <Button onClick = {() => integrateGithub().then((res) => window.open(res.data, "_blank") )}>Integrate With Github</Button>
                    }



                    
                    <FormControlLabel
                      value="end"
                      control={<Switch color="primary" />}
                      label="Zenefits"
                      labelPlacement="start"
                      style={{paddingTop:"15%"}}
                    />
                  </FormGroup>
            </FormControl>

              :
            <CircularProgress  />
          }

        </Grid> 
    );
}