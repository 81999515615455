import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withRoot from '../../../withRoot'
import { withStyles } from '@material-ui/core/styles'
import RegistrationPage from 'core/components/registration/RegistrationPage'
import RegistrationDeviceType from 'core/components/registration/RegistrationDeviceType'
import RegistrationDeviceVerify from 'core/components/registration/RegistrationDeviceVerify'
import ActivateEmailPage from  'core/components/registration/ActivateEmailPage'
// Material Components
import Grid from '@material-ui/core/Grid'
import MobileStepper from '@material-ui/core/MobileStepper';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

import { isUserLoaded } from 'common/helpers/storage'

const styles = theme => ({
    root: {
        width: '90%',
        marginTop: "15%"

    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    stepTracker: {
        backgroundColor: '#fafafa'
    },
})

const getSteps = () => {
    return ['Information', 'Email Verification' ,'Select Device', 'Verify Device']
}

class RegistrationStepper extends Component {
    constructor(props) {
        super(props)
        this.updatePredicate = this.updatePredicate.bind(this);
        this.widthBreakPoint = 700
        this.state = {
            activeStep: this.getActiveStep(),
            skipped: new Set(),
            displayEmailVerification: false,
            isDesktop: window.innerWidth > this.widthBreakPoint
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.updatePredicate);
      }
    
    componentWillUnmount() {
        window.removeEventListener("resize", this.updatePredicate);
    }

    updatePredicate() {
        this.setState({ isDesktop: window.innerWidth > this.widthBreakPoint });
    }
    

    static propTypes = {
        classes: PropTypes.object.isRequired,
        isEmailActivated: PropTypes.bool.isRequired,
        isDeviceVerified: PropTypes.bool.isRequired,
        isSettingDevice: PropTypes.bool.isRequired
    }

    getActiveStep = () => {
        // If the user isn't authenticated always start at 0
        if(!isUserLoaded()){
            return 0
        }
        if (!this.props.isEmailActivated) {
            return 1
        }else if (this.props.isDeviceVerified){
            return 3
        }
        else if (this.props.isSettingDevice) {
            return 2
        }
        else {
            return 2
        }
    }



    getStepContent = (step, handleNext) => {
        switch (step) {
            case 0:
                return <RegistrationPage handleNext={this.gotoEmailVerification} />
            case 1:
                return <ActivateEmailPage handleNext={handleNext} />
            case 2:
                return <RegistrationDeviceType handleNext={handleNext} />
            case 3:
                return <RegistrationDeviceVerify handleNext={handleNext} isDeviceVerified={this.props.isDeviceVerified}/>
            default:
                return 'Unknown step'
        }
    }

    isStepOptional = step => {
        return step === 2
    }

    isStepSkipped(step) {
        return this.state.skipped.has(step)
    }

    // TODO I'm pretty sure this can be removed
    componentWillUpdate = (nextState, nextProps) => {
        if (this.props.isSettingDevice !== nextProps.isSettingDevice) return true
        if (this.props.isDeviceVerified !== nextProps.isDeviceVerified) return true
        if (this.state.activeStep !== nextState.activeStep) return true
        if (this.state.skipped !== nextState.skipped) return true
        return false
    }

    // The registration page, where the person puts user and pass goes to the email verification page
    gotoEmailVerification = () => {
        this.setState({
            displayEmailVerification: true,
            activeStep: 1
        })
    }

    handleNext = () => {
        const { activeStep } = this.state
        let { skipped } = this.state
        if (activeStep === 0) {
            this.setState({displayEmailVerification: true})
        }
        if (this.isStepSkipped(activeStep)) {
            skipped = new Set(skipped.values())
            skipped.delete(activeStep)
        }
        this.setState({
            activeStep: activeStep + 1,
            skipped,
        })

    }

    handleBack = () => {
        const { activeStep } = this.state
        this.setState({
            activeStep: activeStep - 1,
        })
    }

    handleSettingDevice = isSettingDevice => {
        if (isSettingDevice) {
            this.setState({activeStep: 2})
        }
    }

    render = () => {
        const { classes } = this.props
        const steps = getSteps()
        const { activeStep } = this.state

        return (
            <Grid item xs={12} md={8} className={classes.root}>
                <Grid container justify={'center'}>
                    <Grid item xs={12}>
                        <Stepper orientation={ this.state.isDesktop ? "horizontal" : "vertical" } activeStep={activeStep}>
                            {steps.map((label, index) => {
                                const props = {}
                                const labelProps = {}
                                if (this.isStepOptional(index)) {
                                    labelProps.optional = <Typography variant="caption">Optional</Typography>
                                }
                                if (this.isStepSkipped(index)) {
                                    props.completed = false
                                }
                                return (
                                    <Step key={label} {...props}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                )
                            })}
                        </Stepper>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        {activeStep === steps.length ? (
                            <div>
                                <Typography className={classes.instructions}>
                                    All steps completed - you&quotre finished
                                </Typography>
                                <Button onClick={this.handleReset} className={classes.button}>
                                    Reset
                                </Button>
                            </div>
                        ) : (
                            <div>
                                {this.getStepContent(activeStep, this.handleNext)}
                            </div>
                            )}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default withRoot(withStyles(styles, { withTheme: true })(RegistrationStepper))