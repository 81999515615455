import React, {useState} from 'react'

// Team
import Members from './Members';

// lmbk
import withRoot from '../../../../../withRoot'
import { withStyles } from '@material-ui/core/styles'

// Material UI
import Grid from '@material-ui/core/Grid'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    card: {
      width: '100%'
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    title: {
        fontSize: 14,
      }
  });

const Team = props => {
    const {classes, team, user} = props
    const [expanded, setExpanded] = useState(false)

    return(
        <Grid container spacing={3}>
            <Card className={classes.card} variant="outlined">
                <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        {team.name}
                    </Typography>
                </CardContent>
                <CardActions>
                    <IconButton
                        onClick={() => setExpanded(!expanded)}
                        aria-expanded={expanded}
                        aria-label="show members and configuration"
                        >
                        <ExpandMoreIcon />
                    </IconButton>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Typography><Members team = {team} user = {user} /></Typography>
                    </CardContent>
                </Collapse>
            </Card>
        </Grid>
    )
}

export default withRoot(withStyles(styles, { withTheme: true })(Team))
