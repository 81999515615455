import React from 'react'
import { isAuthenticated } from 'common/helpers/auth'
import {
  Route,
  Redirect
} from 'react-router-dom'
import { token } from 'common/helpers/storage'
import api from 'common/helpers/api'

const PrivateRoute = ({ component: Component, ...rest }) => {
    api.configure(token())
    return(
        <Route {...rest} render={(props) => (
            isAuthenticated()
            ? <Component {...props} />
            : <Redirect 
                to= {{ 
                    pathname: '/login',
                    state: { from: props.location }
                }}
            />
        )} />
    )
}

export default PrivateRoute;