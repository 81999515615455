const QUOTES_LIST = ["We're better off together.", "Dream team... assemble!", "Teams are the meme.", "Teamwork makes the dreamwork."]

// Function to randomly grab an item from an Array
// or return null if it is empty
const _getRandomArrayItem = (arr) => {
  if (arr.length === 0) {
    return null
  }

  const randomIndex = Math.floor(Math.random() * Math.floor(arr.length))
  return arr[randomIndex]
}

// Function to get a random quote
const getRandomQuote = () => {
  return _getRandomArrayItem(QUOTES_LIST)
}

export {
  _getRandomArrayItem as getRandomArrayItem,
  getRandomQuote,
  QUOTES_LIST
}
