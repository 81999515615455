export const REQUEST_AUTH_LOGIN = 'REQUEST_AUTH_LOGIN'
export const REQUEST_AUTH_LOGOUT = 'REQUEST_AUTH_LOGOUT'
export const RECEIVE_AUTH_SUCCESS = 'RECEIVE_AUTH_SUCCESS'
export const RECEIVE_AUTH_ERROR = 'RECEIVE_AUTH_ERROR'

const _requestAuthLogin = (usernameEmail, password) => {
    return {
        type: REQUEST_AUTH_LOGIN,
        usernameEmail,
        password
    }
}

const _receiveAuthSuccess = authData => {
    return {
        type: RECEIVE_AUTH_SUCCESS,
        data: authData,
        recievedAt: Date.now()
    }
}

const _receiveAuthError = error => {
    return {
        type: RECEIVE_AUTH_ERROR,
        error,
        recievedAt: Date.now()
    }
}

const _currentAuthStatus = () => {
    return {
        type: CURRENT_AUTH_STATUS
    }
}

const _requestAuthLogout = () => {
    return {
        type: REQUEST_AUTH_LOGOUT
    }
}

export const fetchAuth = (usernameEmail, password) => {
    return dispatch => {
        dispatch(_requestAuthLogin(usernameEmail, password))
        .then(authData => {
            dispatch(_receiveAuthSuccess(authData))
            return authData
        })
        .catch(e => {
            dispatch(_receiveAuthError({authenticated: false, error: e}))
            throw 'Not authenticated.'
        })
    }
}

export const logoutAuth = () => {
    return dispatch => {
        dispatch(_requestAuthLogout())
        .then()
    }
}

export const checkAuth = () => {

}