import React, {useRef, useContext} from 'react'
import {createShareURL} from 'common/helpers/team'
import TextField from '@material-ui/core/TextField';
import FileCopyTwoToneIcon from '@material-ui/icons/FileCopyTwoTone';
import Button from '@material-ui/core/Button'
import { mergeClasses } from '@material-ui/styles';
import { withStyles } from '@material-ui/core/styles'
import withRoot from 'root/withRoot'
import { NotificationContext } from 'common/context/Notifications'

const styles = theme => ({
    copyIcon: {
        margin: "3px"
    }
  });

const Invite = props => {
    const {team, classes} = props
    const textAreaRef = useRef(null);
    const notifContext = useContext(NotificationContext)

    const copyToClip = (e) => {
        textAreaRef.current.select();
        document.execCommand('copy');

        e.target.focus();
        notifContext.notify('Invite link copied')
    }

    return(
        <div>
            {document.queryCommandSupported('copy') && <Button onClick={copyToClip}>Copy Invite <FileCopyTwoToneIcon className = {classes.copyIcon}/> </Button>  }

            <TextField
                inputRef={textAreaRef}
                value={createShareURL(team.id, team.token)}
            />
        </div>
    )
}

export default withRoot(withStyles(styles, { withTheme: true })(Invite))
