import axios, * as api from 'axios'
import { token } from 'common/helpers/storage'

export const lmbkApi = api.create()

lmbkApi.configure = (token) => {
    if (token) {
        lmbkApi.defaults.headers.common["Authorization"] = `Token ${token}`
    }
}

const activeToken = token()
lmbkApi.configure(activeToken)

export default lmbkApi
