import React, { useState, useContext } from 'react'
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import {deleteTeam} from 'common/helpers/team'
import {TeamsContext} from '../../context/TeamsContext'

const DeleteTeam = props => {
    const {team} = props
    const [teams, setTeams] = useContext(TeamsContext)
    const [open, setOpen] = useState(false)

    const removeTeam = () => {
        deleteTeam(team.id)
        setOpen(false)
        setTeams(teams.filter((t) => t.id != team.id))
    } 

    return(
        <div>
            <Button variant="contained" color="secondary" onClick = { () => setOpen(true) }>
                Delete Team
            </Button>
            <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={open}
            >
                <DialogTitle id="confirmation-dialog-title">Are you sure?</DialogTitle>
                <DialogContent dividers>
                    
                    <Button variant="contained" color="secondary" onClick = { () => removeTeam() }>
                        Yes
                    </Button>
                    <Button variant="contained" color="secondary" onClick = { () => setOpen(false) }>
                        No
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default DeleteTeam;