var default_onmessage = (e) => console.log('Message:', e.data);
export var connect = (url, onmessage = default_onmessage ) => {
    var ws = new WebSocket(url);

    ws.onmessage = onmessage

    ws.onclose = (e) => {
        console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
        setTimeout(function() {
        connect(url, onmessage);
        }, 1000);
    };

    ws.onerror = (err) => {
        console.error('Socket encountered error: ', err, err.message, 'Closing socket');
        ws.close();
    };
    return(ws)
}