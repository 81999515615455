import React, { useState } from 'react'
import Snackbar from '@material-ui/core/Snackbar'

let notifications = {
    value: "",
    open: false,
    notify: () => {}
}

export const NotificationContext = React.createContext(notifications);

export const NotificationProvider = props => {
    const [ notification, setNotification ] = useState(null)
    const [ open, setOpen ] = useState(false)

    const value = {
        notification: notification,
        open: open,
        notify: (notification) => {
            setNotification(notification)
            setOpen(true)
        },
        onClose: () => {
            setOpen(false)
        }
    }

    return(
        <NotificationContext.Provider value = { value }>
            { props.children }
        </NotificationContext.Provider>
    )
}

export const NotificationDisplay = props => {
    return(
        <NotificationContext.Consumer>
            {({notification, open, onClose}) => {
                return(
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={open}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        autoHideDuration = { 6000 }
                        onClose = { onClose }
                        message={<span id="message-id">{notification}</span>}
                    />
                )

            }}
        </NotificationContext.Consumer>
    )

}
