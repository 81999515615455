import React, { useState, useEffect } from 'react'
import withRoot from '../../../withRoot'
import { withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container';
import SurveyForm from 'survey/SurveyForm'
import { getValidSurveys } from 'common/helpers/survey'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = theme => ({
    loading: {
        margin: '0 auto'
      }
})

const SurveyContainer = (props) => {
    const { classes } = props
    const [loading, setLoading] = useState(true)
    const [urls, setUrls] = useState([])
    const [urlIndex, setUrlIndex] = useState(0)


    useEffect(() => {
        getValidSurveys().then((res) => {
            setUrls(res.data)
            setLoading(false)
        }
        )
    }, [])

    const questionAnswered = () => {
        if(urls.length - 1 > urlIndex) {
            setLoading(true)
            setUrlIndex(urlIndex + 1)
        }
    }


    useEffect(() => {
        if(urlIndex) setLoading(false);
    }, [urlIndex])

    return(
        <Container maxWidth="lg">
            {  !loading ?
                <div>
                    <SurveyForm 
                        url = {urls[urlIndex]} 
                        urlIndex = {urlIndex} 
                        submit = {() => questionAnswered()}
                        width = '100%'
                        height = '100%'
                    />
                </div>
                :
                <CircularProgress className = {classes.loading} />
            }
        </Container>
    )
}

export default withRoot(withStyles(styles, {withTheme: true})(SurveyContainer))
