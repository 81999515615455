import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import withRoot from '../../../withRoot'
import moment from 'moment'
import classNames from 'classnames'

// Material Components
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Grid from '@material-ui/core/Grid'

// Internal Helpers
import { fetchDeviceActive } from 'common/helpers/device'
import { fetchLSIHistory, lsiHistoryToGraph } from 'common/helpers/lsi'
import SpacedTypography from 'common/components/SpacedTypography'
import CircularProgress from '@material-ui/core/CircularProgress'

import {
  LSIGraph,
  DAILY_GRAPH_TYPE,
  WEEKLY_GRAPH_TYPE,
  MONTHLY_GRAPH_TYPE,
  YEARLY_GRAPH_TYPE
} from 'dashboard/components/LSIGraph'

import LSISummaryCards from 'dashboard/components/LSISummaryCards'
import { getMinAvgMaxLsi } from 'common/helpers/lsiTools'
import { NotificationContext } from 'common/context/Notifications'


// Styles
const styles = theme => ({
    root: {
        flexGrow: 1,
        'text-align': 'center'
    },
    graphs: {
      marginTop: '2%'
    },
    tab: {
        backgroundColor: theme.palette.background.paper
    },
    title: {
      spacing: 40
    },
    loading: {
      margin: '0 auto'
    },
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
  }
})

function TabContainer(props) {
    return (
        <SpacedTypography component="div" style={{ padding: 8 * 3 }} className={ props.className }>
            {props.children}
        </SpacedTypography>
    )
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
}


class DashboardStats extends Component {
  constructor(props) {
      super(props)
  }

  static propTypes = {
      classes: PropTypes.object.isRequired
  }

  state = {
    value: 0,
    lsiGraphType: DAILY_GRAPH_TYPE,
    lsiHistory: [],
    minLsi: null,
    avgLsi: null,
    maxLsi: null,
    timeRange: [],
    loading: true
  }

  handleTabChange = (event, value) => {
    let graphType = null

    switch(value) {
      case 0:
        graphType = DAILY_GRAPH_TYPE
        break
      case 1:
        graphType = WEEKLY_GRAPH_TYPE
        break
      case 2:
        graphType = MONTHLY_GRAPH_TYPE
        break
      case 3:
        graphType = YEARLY_GRAPH_TYPE
        break
      default:
        graphType = this.state.lsiGraphType
    }

    this.setState({
      value: value,
      lsiGraphType: graphType,
      lsiHistory: [],
      minLsi: null,
      avgLsi: null,
      maxLsi: null,
      loading: true
    })
  }

  componentWillMount() {
    this.updateLSIHistory()
  }

  componentDidUpdate(prevProps, prevState) {
    // Typical usage (don't forget to compare props):
    if (this.state.lsiGraphType !== prevState.lsiGraphType) {
      this.updateLSIHistory()
    }
  }

  getLSIHistoryStartEndDates = () => {
    const timePeriod= this.state.lsiGraphType

    let currentTime = null
    switch (timePeriod) {
      case DAILY_GRAPH_TYPE:
        currentTime = moment().utc().endOf('day')
        return {
          startDate: currentTime.clone().subtract(1, 'days'),
          endDate: currentTime
        }
      case WEEKLY_GRAPH_TYPE:
        currentTime = moment().utc().endOf('week')
        return {
          startDate: currentTime.clone().subtract(1, 'weeks'),
          endDate: currentTime
        }
      case MONTHLY_GRAPH_TYPE:
        currentTime = moment().utc().endOf('month')
        return {
          startDate: currentTime.clone().subtract(1, 'months'),
          endDate: currentTime
        }
      case YEARLY_GRAPH_TYPE:
        currentTime = moment().utc().endOf('year')
        return {
          startDate: currentTime.clone().subtract(1, 'years'),
          endDate: currentTime
        }
      default:
        return null
    }
  }

  updateLSIHistory = () => {

    return fetchDeviceActive()
      .then(deviceResponse => {
        if (deviceResponse) {
          const dates = this.getLSIHistoryStartEndDates()

          if (dates === null) return null
          const requestLsiHistory = fetchLSIHistory(dates)

          requestLsiHistory
          .then(lsiHistoryResponse => {
            if (lsiHistoryResponse) {
              let lsiHistoryData = lsiHistoryResponse.lsiHistory.data
              let transformedData = lsiHistoryData.map(lsiHistoryToGraph)
                                                  .filter(xAndY => xAndY.y > 0)

              const { minLsi, avgLsi, maxLsi } = getMinAvgMaxLsi(transformedData.map(lsi => lsi.y))
              this.setState({
                lsiHistory: transformedData,
                timeRange: [moment(lsiHistoryResponse.lsiStartDate).unix(), moment(lsiHistoryResponse.lsiEndDate).unix()],
                minLsi: minLsi,
                avgLsi: avgLsi,
                maxLsi: maxLsi,
                loading: false
              })
              if(!transformedData.length)
                this.context.notify("Uh oh, there's no data for this time period... try syncing your Garmin device with your phone 📱");
            }
          }).catch(e => console.log('fetchInfoError:', e))
        }else{
          return('error')
        }
    })
    .catch(e => console.log('fetchInfoError:', e))
  }

  a11yProps = (index) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  render() {
    const { classes, theme } = this.props
    const { value, lsiGraphType, lsiHistory, timeRange, minLsi, maxLsi, avgLsi} = this.state

    return (
      <Grid container className={ classes.root } >
        <Grid item xs={12}>
          <Grid container direction="column" alignItems="center">
            <Grid item xs={8}>
              <SpacedTypography variant="h4" style={{padding: "0em"}}>
                Your Stress Level Statistics
              </SpacedTypography>
            </Grid>
            <Grid item xs={12}>
              {
                (!this.state.loading) ?
                <LSISummaryCards minLsi={minLsi} maxLsi={maxLsi} avgLsi={avgLsi}/> : <CircularProgress className={classes.loading} />
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid container justify="center" className={classNames(classes.root, classes.graphs)}>
          <Grid item xs={8}>
            <SpacedTypography variant="h4">
              Your Historical Stress Levels
            </SpacedTypography>

              <span>
                <AppBar position="static">
                  <Tabs orientation="horizontal"  variant="fullWidth" centered value={value} onChange={ this.handleTabChange } >
                    <Tab  label="Today" {...this.a11yProps(0)} />
                    <Tab  label="This Week" {...this.a11yProps(1)} />
                    <Tab  label="This Month" {...this.a11yProps(2)} />
                    <Tab  label="This Year" {...this.a11yProps(3)}/>
                  </Tabs>
                </AppBar>
                <TabContainer className={ classes.tab }>
                {
                  (!this.state.loading) ?
                    <LSIGraph timePeriod={lsiGraphType} lsiHistory={lsiHistory} timeRange={timeRange}/>
                    :
                    <CircularProgress className= {classes.loading} />
                }
                </TabContainer>
              </span>


          </Grid>
        </Grid>
      </Grid>
    )
  }
}

DashboardStats.contextType = NotificationContext;

export default withRoot(withStyles(styles, { withTheme: true })(DashboardStats))
