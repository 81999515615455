import config from 'app/config'
import api from 'common/helpers/api'



const _getUserDetail = (user_id) => {
    return api.get(`${config.API_URL}/user/${user_id}`)
        .then((res) => {
            return res.data
        })
        .catch((e) => {
            console.error(e)
        })
}

export const updateUser = (user_id, userData) => {
    return api.patch(`${config.API_URL}/user/${user_id}`, userData)
}

// const _getLSIHistory = async (data) => {
//   const { startDate, endDate } = dates
//   const data = {
//     start_date: startDate.format(),
//     end_date: endDate.format()
//   }
//   try {
//     return {
//       lsiHistory: await api.post(`${config.API_URL}/lsi/history/`, data),
//       lsiStartDate: startDate.toDate(),
//       lsiEndDate: endDate.toDate()
//     }
//   } catch (error) {
//     console.log(error)
//   }
//   return response
// }

// api request for an object for lsi
export const fetchUserDetail = (user_id) => {
    return _getUserDetail(user_id)
}

// api request for an array of lsiHistory objects
// export const fetchLSIHistory = (interval) => {
//     return _getLSIHistory(interval)
// }
