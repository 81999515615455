import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import withRoot from '../../../../withRoot'
import { NotificationContext } from 'common/context/Notifications'

// Material Components
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'

// lmbk imports
import { processRegister } from 'common/helpers/auth'

const styles = theme => ({
    container: {
        marginTop: '5%'
    },
    root: {
        margin: '32px',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    header: {
        backgroundColor: theme.palette.primary.main,
        width: '100%',
        padding: '8px',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px'
    },
    button: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginTop: '32px',
        marginBottom: theme.spacing(1),
        '&:hover': {
            backgroundColor: theme.palette.secondary.main
        }
    }
})

class RegistrationPage extends Component {
    constructor(props) {
        super(props)
    }

    state = {
        username: '',
        email: '',
        password: '',
        first_name: '',
        last_name: '',
        company_code: '',
        terms: false,
        isSaving: false,
    }

    static propTypes = {
        classes: PropTypes.object.isRequired,
        handleNext: PropTypes.func.isRequired,
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value })
    }

    getTermsContent = () => {
        return (<span>Do you accept our <a href='https://app.termly.io/document/terms-of-use-for-saas/2a89a30e-05e3-4dbd-aa1b-b38a726cbc60' target="_blank">terms of service</a>?</span>)
    }

    handleRegistration = () => {
        const { username, email, password, first_name, last_name, company_code, terms} = this.state
        if (terms) {
            this.setState({isSaving: true})
            const credentials = {
                username: username,
                email: email,
                password: password,
                first_name: first_name,
                last_name: last_name,
                company_code: company_code
            }
            return processRegister(credentials)
            .then(res => {
                this.context.notify('A verification link has been sent to your email to verify your account')
                this.setState({isSaving: false})
                this.props.handleNext()
            }).catch((e) => {
                Object.entries(e.response.data).map(([field, error]) => {
                    this.context.notify(`${field.replace("_", " ")} : ${error}`)
                })
                this.setState({ isSaving: false })
            })
        }
        else {
            this.context.notify('Please agree to the terms and conditions')
            this.setState({ isSaving: false })
        }
    }

    render() {
        const { classes } = this.props
        return (
            <Grid container alignItems={'center'} justify={'center'} direction={'column'} className={classes.container}>
                <Grid item xs={12}>
                    <Paper className={classes.root} elevation={4}>
                        <Grid container className={classes.header} direction="column">
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h3" align="center">
                                    Welcome to lmbk
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" align="center">
                                    register below
                                </Typography>
                            </Grid>
                        </Grid>
                        <FormControl className={classes.root} noValidate autoComplete="off" component="fieldset">
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                required
                                                id="first_name"
                                                label="first name"
                                                className={classes.textField}
                                                value={this.state.first_name}
                                                onChange={this.handleChange('first_name')}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                required
                                                id="last_name"
                                                label="last name"
                                                className={classes.textField}
                                                value={this.state.last_name}
                                                onChange={this.handleChange('last_name')}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                required
                                                id="email"
                                                label="Email"
                                                className={classes.textField}
                                                value={this.state.email}
                                                onChange={this.handleChange('email')}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                required
                                                id="username"
                                                label="username"
                                                className={classes.textField}
                                                value={this.state.username}
                                                onChange={this.handleChange('username')}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                required
                                                id="password"
                                                label="password"
                                                className={classes.textField}
                                                value={this.state.password}
                                                type="password"
                                                onChange={this.handleChange('password')}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="company_code"
                                                label="company code"
                                                className={classes.textField}
                                                value={this.state.company_code}
                                                onChange={this.handleChange('company_code')}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={this.state.terms}
                                                        onChange={this.handleChange('terms')}
                                                        value="terms"
                                                        color="secondary"
                                                    />
                                                }
                                                label={this.getTermsContent()}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Button variant="contained" color="primary" className={classes.button} disabled={this.state.isSaving} onClick={this.handleRegistration.bind(this)}>
                                continue
                            </Button>
                        </FormControl>
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}

RegistrationPage.contextType = NotificationContext;

export default withRoot(withStyles(styles, { withTheme: true })(RegistrationPage))
