import React, { useState } from 'react'

const initialState = {team: []};


export const TeamsContext = React.createContext(initialState);



export const TeamsProvider = props => {
    const [ teams, setTeam ] = useState(null)

    return(
        <TeamsContext.Provider value = { [teams, setTeam] }>
            { props.children }
        </TeamsContext.Provider>
    )
}
