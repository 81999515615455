import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import withRoot from '../../../withRoot'

// Material Components
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import SpacedTypography from 'common/components/SpacedTypography'

// Material Colors
import red from '@material-ui/core/colors/red'
import orange from '@material-ui/core/colors/orange'
import teal from '@material-ui/core/colors/teal'
import grey from '@material-ui/core/colors/grey'

// lmbk code
import {
  LSIGraph,
  DAILY_GRAPH_TYPE,
  WEEKLY_GRAPH_TYPE,
  MONTHLY_GRAPH_TYPE
} from 'dashboard/components/LSIGraph'

import { isValidLSIRange, isLsiLow, isLsiMedium, isLsiHigh } from 'common/helpers/lsiTools'

// Styles
const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: '5%'
    },
    card: {
      minWidth: 150,
    },
    stressLow: {
        backgroundColor: teal['A400']
    },
    stressMedium: {
        backgroundColor: orange['A400']
    },
    stressHigh: {
        backgroundColor: red['A400']
    },
    stressUnknown: {
        backgroundColor: grey[500]
    }
})


class LSISummaryCards extends Component {
  constructor(props) {
      super(props)
  }

  static propTypes = {
      classes: PropTypes.object.isRequired,
      minLsi: PropTypes.number,
      avgLsi: PropTypes.number,
      maxLsi: PropTypes.number
  }

  stressColor = stressLvl => {
      if (isLsiLow(stressLvl)) return this.props.classes.stressLow
      if (isLsiMedium(stressLvl))return this.props.classes.stressMedium
      if (isLsiHigh(stressLvl)) return this.props.classes.stressHigh
      return this.props.classes.stressUnknown
  }

  getStressData = dataType => {
    switch(dataType) {
      case 'avg':
        return isValidLSIRange(this.props.avgLsi) ? this.props.avgLsi : '?'
      case 'low':
        return isValidLSIRange(this.props.minLsi) ? this.props.minLsi : '?'
      case 'high':
        return isValidLSIRange(this.props.maxLsi) ? this.props.maxLsi : '?'
      default:
        return '?'
    }
  }

  render() {
    const { classes } = this.props

    return (
      <Grid container justify="center" direction="row" spacing={1}>
        <Grid item xs={12} md={4}>
        <SpacedTypography variant="h5" align="center">
          Low
        </SpacedTypography>
        <Card className={classes.card} classes={{ root: this.stressColor(this.getStressData('low'))}}>
          <CardContent>
            <SpacedTypography variant="h2" align="center">
              { this.getStressData('low') }
            </SpacedTypography>
          </CardContent>
        </Card>
        </Grid>
        <Grid item xs={12} md={4}>
        <SpacedTypography variant="h5" align="center">
          Average
        </SpacedTypography>
        <Card className={classes.card} classes={{ root: this.stressColor(this.getStressData('avg')) }}>
          <CardContent>
            <SpacedTypography variant="h2" align="center">
              { this.getStressData('avg') }
            </SpacedTypography>
          </CardContent>
        </Card>
        </Grid>
        <Grid item xs={12} md={4}>
        <SpacedTypography variant="h5" align="center">
          High
        </SpacedTypography>
        <Card className={classes.card} classes={{ root: this.stressColor(this.getStressData('high')) }} >
          <CardContent>
            <SpacedTypography variant="h2" align="center">
              {this.getStressData('high')}
            </SpacedTypography>
          </CardContent>
        </Card>
        </Grid>
      </Grid>
    )
  }
}

export default withRoot(withStyles(styles, { withTheme: true })(LSISummaryCards))
