import React, { Component } from 'react'
import Sidebar from 'common/components/Sidebar'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import withRoot from '../../../withRoot'
import { withStyles } from '@material-ui/core/styles'

// Internal Components
import Dashboard from 'dashboard/components/Dashboard'

// Internal Helpers
import { fetchDeviceActive } from 'common/helpers/device'
import { fetchLSI, fetchLSIHistory } from 'common/helpers/lsi'

const styles = theme => ({})

const initialState = {
    lsiHistory: [],
    isDeviceLinked: false
}
class DashboardContainer extends Component {
    constructor(props) {
        super(props)
    }

    static propTypes = {
        classes: PropTypes.object.isRequired
    }

    state = initialState

    render = () => {
        const { classes } = this.props
        const { lsi, lsiHistory, isDeviceLinked } = this.state
        return (
            <Dashboard className={classes} />
        )
    }
}

export default withRoot(withStyles(styles, { withTheme: true })(DashboardContainer))
