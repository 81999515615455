import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import withRoot from '../../../../withRoot'
import { watchServer } from 'common/helpers/auth'
import Button from '@material-ui/core/Button'

// Material Components
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = theme => ({
    container: {
        marginTop: '5%'
    },
    root: {
        margin: '32px',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    header: {
        width: '100%',
        padding: '8px',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px'
    },
    progress: {
        margin: '32px'
    }
})

class RegistrationDeviceVerify extends Component {
    constructor(props) {
        super(props)
        var ws = watchServer(this.onmessage)
        ws.onmessage = this.onmessage
    }

    onmessage = (e) => {
        var device_activated = JSON.parse(JSON.parse(e.data).text).device_activated
        console.log("Message sent", e)
        if(device_activated){
            this.setState({isDeviceVerified: true})
        }
    }

    static propTypes = {
        classes: PropTypes.object.isRequired,
        isDeviceVerified: PropTypes.bool.isRequired,
        handleNext: PropTypes.func,
    }

    state = {
        loading: true,
        isDeviceVerified: false
    }

    componentWillUpdate = (nextState, nextProps) => {
        if (this.props.isDeviceVerified !== nextProps.isDeviceVerified) return true
        if (this.state.loading !== nextState.loading) return true
        return false
    }

    handleVerfied = () => {
        window.location.pathname = '/cortex'
    }

    render() {
        const { classes } = this.props
        return (
            <Grid container alignItems={'center'} justify={'center'} direction={'column'} className={classes.container}>
                <Grid item xs={12}>
                    {this.state.loading && !this.props.isDeviceVerified && !this.state.isDeviceVerified
                    ? <CircularProgress className={classes.progress} color="primary" size={60} thickness={8} />
                    : <Paper className={classes.root} elevation={4}>
                        <Grid container className={classes.header} direction="column">
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h3" align="center">
                                    Device Verfied
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" align="center">
                                    congrats, your device has been connected!
                                    welcome to lmbk
                                </Typography>
                            </Grid>
                        </Grid>
                     </Paper>
                    }
                </Grid>
                {this.state.isDeviceVerified &&
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" className={classes.button} onClick={this.handleVerfied.bind(this)}>
                        enter lmbk
                    </Button>
                </Grid>
                }
            </Grid>
        )
    }
}

export default withRoot(withStyles(styles, { withTheme: true })(RegistrationDeviceVerify))