import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import withRoot from '../../../withRoot'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import Confirmation from 'common/components/Confirmation'

const styles = theme => ({
    buttonContainer: {
        textAlign: 'center'
    },
    confirmed: {
        fontSize: 100,
        marginTop: '20px'
    },
    header: {
        backgroundColor: theme.palette.primary.main,
        width: '100%',
        padding: '8px'
    }
})

const ConfirmationPage = props => {
    const { classes } = props
    return(
        <Confirmation>
                <Grid item xs={12}>
                    <Grid container className={classes.header} direction="column">
                        <Typography variant="h5" component="h3" align="center">
                            Confirmed!
                        </Typography>
                    </Grid>
                </Grid>
            <Grid item xs={12} className = {classes.buttonContainer}>
                    <Icon className={`fa fa-check-circle ${classes.confirmed}`} />
                    <Typography variant="h5" component="h3" align="center">
                        You can close this page
                    </Typography>
            </Grid>
        </Confirmation>
    )
}

export default withRoot(withStyles(styles, { withTheme: true })(ConfirmationPage))