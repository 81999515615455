import config from 'app/config'
import api from 'common/helpers/api'
import { token } from 'common/helpers/storage'

const _getFeatureAccess = () => {
    const url = `${config.API_URL}/user/feature_access/`
    return api.get(url)
        .then(res => {
            return res.data
        })
        .catch(e => {
            return false
        })
}

// api request to check if there is a device active for the account
export const fetchDeviceActive = () => {
    return _getFeatureAccess()
    .then(res => {
        if (res) {
            return res.has_active_device
        }
        else {
            console.log("Device: ", res)
            return false
        }
    })
    .catch(e => console.log('getDeviceError', e))
}