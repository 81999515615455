import { combineReducers } from 'redux'
import { Map } from 'immutable'
import auth from './auth'
import garmin from './garmin'
import { connectRouter } from 'connected-react-router'

const initialState = Map()

const app = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state
    }
}

const rootReducer = (history) => {
    return combineReducers({
        app,
        auth,
        garmin,
        router: connectRouter(history)
    })
}

export default rootReducer
