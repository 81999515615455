import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import withRoot from '../../../withRoot'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'

const styles = theme => ({
    container: {
        marginTop: '10%',
        width: '100%',
        height: '100%'
    },
    root: {
        margin: '32px',
        width: '400px',
        height: '400px'
    }
})

const Confirmation = props => {
    const { classes } = props
    return(
        <Grid container alignItems={'center'} justify={'center'} direction={'column'} className={classes.container}>
        <Grid item xs={12}>
            <Paper className={classes.root} elevation={4}>
                    {props.children}
            </Paper>
        </Grid>
    </Grid>
    )
}

export default withRoot(withStyles(styles, { withTheme: true })(Confirmation))