import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import withRoot from '../../../withRoot'

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

// import Box from '@material-ui/core/Box';
// import Paper from '@material-ui/core/Paper';
// import Grid from '@material-ui/core/Grid';

// import account settings

import AccountSettingsApps from './AccountSettingsApps';
import AccountSettingsPrivacy from './AccountSettingsPrivacy';
import AccountSettingsEdit from './AccountSettingsEdit';
import AccountSettings from './AccountSettings';
import TeamConfiguration from './teams/TeamConfiguration';
import { TeamsProvider } from './teams/context/TeamsContext';

function TabContainer(props) {
  return (
      <Typography component="div" style={{ padding: 8 * 3 }} className={ props.className }>
          {props.children}
      </Typography>
  )
}


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <TabContainer p={3}>{children}</TabContainer>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

// const useStyles = makeStyles(theme => ({
//   root: {
//     flexGrow: 1,
//     width: '100%',
//     backgroundColor: theme.palette.background.paper,
//   },
// }));

export default function AccountSettingsBar() {
//   const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search)
    const ally = params.get('ally')
    if(ally){
      setValue(parseInt(ally))
    }
  }, [])




  return (
    <div >
      <AppBar position="static" color="default" >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="You" {...a11yProps(0)} />
          <Tab label="Edit" {...a11yProps(1)} />
          <Tab label="Apps" {...a11yProps(2)} />
          <Tab label="Privacy" {...a11yProps(3)} />
          <Tab label="Teams" {...a11yProps(4)} />

        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} style={{paddingLeft:"3em"}}>

        {value == 0 && <AccountSettings/> }
         
       
      </TabPanel>
      <TabPanel value={value} index={1} style={{paddingLeft:"3em"}}>

        {value == 1 && <AccountSettingsEdit/> }
        
      
        </TabPanel>
      <TabPanel value={value} index={2} style={{paddingLeft:"3em"}}>
      
        <AccountSettingsApps/>
      
      </TabPanel>
      <TabPanel value={value} index={3} style={{paddingLeft:"3em"}}>
        
        <AccountSettingsPrivacy/>
      
        </TabPanel>
        <TabPanel value={value} index={4} style={{paddingLeft:"3em"}}>
        
        <TeamsProvider>
          <TeamConfiguration />
        </TeamsProvider>
      
        </TabPanel>
    </div>
  );
}