import React from "react"
import { PrivateRoute, Route, Switch } from 'react-router-dom'

// Material UI
import Grid from '@material-ui/core/Grid'

// Confirmation Page
import ConfirmationPage from 'core/components/ConfirmationPage'
// Login Page
import LoginPage from 'core/components/LoginPage'
// Registration
import RegistrationContainer from "core/containers/RegistrationContainer"
// Landing Page
import LandingPage from 'core/components/LandingPage'
// Cortex (Dashboard) Page
import DashboardContainer from 'dashboard/containers/DashboardContainer'
// Account Settings
import AccountSettingsContainer from 'account/containers/AccountSettingsContainer'
// Team Page
import TeamContainer from 'team/containers/TeamContainer'
import InviteConfirmation from 'account/components/teams/team_card/InviteConfirmation'
import SurveyContainer from 'survey/containers/SurveyContainer'

// Recovery Page
import RecoverPageContainer from 'account/containers/RecoverPageContainer'

// Update Password Page
import UpdatePasswordContainer from 'account/containers/UpdatePasswordContainer'

import ProtectedRoute from 'common/components/ProtectedRoute'

const Routes = () => {
    return (
        <main>
            <Switch>
                <Route exact path='/' component={LandingPage} />
                <ProtectedRoute path='/cortex' component={DashboardContainer} />
	        <ProtectedRoute path='/survey' component={SurveyContainer} />
                <ProtectedRoute path='/teams' component={TeamContainer} />
                <ProtectedRoute path='/confirmation' component={InviteConfirmation} />
                <Route path='/login' component={LoginPage} />
                <Route path='/success' component={ConfirmationPage} />
                <Route path='/registration' component={RegistrationContainer} />
                <Route path='/accounts' component={AccountSettingsContainer} />
                <Route path='/recover' component={RecoverPageContainer} />
                <Route path='/update' component={UpdatePasswordContainer}/>
            </Switch>
        </main>
    )
}

export default Routes
