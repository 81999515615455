import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withRoot from '../../../withRoot'


// Material Components
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { SketchPicker } from 'react-color'

//internal components
import { updateUser } from 'common/helpers/account'
import { user_id } from 'common/helpers/storage';
import { fetchUserDetail } from 'common/helpers/account'

const styles = theme => ({})



class AccountSettingsEdit extends Component {
    constructor(props) {
        super(props)
        this.init_username = ''
    }
  
    state = {
      first_name: '',
      last_name: '',
      username: '',
      email: '',
      password: '',
      team_color: '#000',
      loading: true
    }

    static propTypes = {
      classes: PropTypes.object.isRequired,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      username: PropTypes.string,
      email: PropTypes.string,
      password: PropTypes.string
    }

    componentDidMount = () => {

      return fetchUserDetail(user_id())
      .then(res => {
          if (res) {
            this.init_username = res.username
            this.setState({
              firstName: res.first_name || '',
              lastName: res.last_name || '',
              username: res.username || '',
              email: res.email || '',
              password: res.password || '',
              loading: false,
              team_color: res.user_settings.team_color
            })
          }
        console.log(firstName)
      })
      .catch(e => console.log('fetchInfoError:', e))
    }
  
    handleChange = name => event => {
      this.setState({
        [name]: event.target.value,
      })
    }
  
    updateUser = () => {
      let payload = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        user_settings: {team_color: this.state.team_color}
      }
      // TODO: There's probably a more streamlined way to do this
      // We don't want to send the username if it's the same
      console.log("User on send, ", payload)
      if(this.state.username != this.init_username){
          payload['username'] = this.state.username
      }
      updateUser(user_id(), payload).then((res) => console.log(res)).catch(e => console.log("Update User Error: ", e))
    }
  
    render() {
        const classes = this.props.classes
        const { firstName, lastName, username, team_color} = this.state
        
        return (
          

          <React.Fragment >
          { (!this.state.loading) ?    
          
          <Grid item style={{paddingTop:"0%"}}>
            <form className={classes.container} noValidate autoComplete="off">
              <div>
                <TextField
                  id="firstName"
                  label="First Name"
                  className={classes.textField}
                  value={firstName || ''}
                  onChange={this.handleChange('firstName')}
                  margin="normal"
                  
                />
              </div>
              <div>
                <TextField
                  id="lastName"
                  label="Last Name"
                  className={classes.textField}
                  value={lastName || ''}
                  onChange={this.handleChange('lastName')}
                  margin="normal"
                  
                />
              </div>
              <div style={{marginBottom: "1em"}}>
                <TextField
                  id="username"
                  label="Username"
                  className={classes.textField}
                  value={username || ''}
                  onChange={this.handleChange('username')}
                  margin="normal"
                  
                />
              </div>
              <div style={{marginBottom: "1em"}}>
              <label>Team Color</label>
              <SketchPicker
                  name='team_color'
                  color={team_color}
                  id="team_color"
                  label="Team Color"
                  onChange={(color) => {if(color) this.setState({team_color: color.hex});}}
                  margin="normal"
                />
              </div>
            </form>
            <Button onClick={ this.updateUser } style={{fontSize: "75%"}} size="large" variant="contained" color="secondary"  > Submit Changes</Button> 
          </Grid>
          
          : 
          
          <CircularProgress  />
          
          }
          </React.Fragment>

          
        )
    }
  }
  
export default withRoot(withStyles(styles, { withTheme: true })(AccountSettingsEdit))
