import React, {useState} from 'react'

// Team
import Team from './team_card/Team'

// lmbk
import withRoot from '../../../../withRoot'
import { withStyles } from '@material-ui/core/styles'


const styles = theme => ({
    card: {
      width: '100%'
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    title: {
        fontSize: 14,
      }
  });

const Teams = (props) => {
    const {teams, user } = props

    return(
        teams.map((team) => {
            return(
                <Team team = {team} user = {user} key = {team.id} />
            )
        })
    )
}

export default withRoot(withStyles(styles, { withTheme: true })(Teams))
