const _getLmbkStorage = () => {
    return JSON.parse(sessionStorage.getItem('lmbk'))
}
export const storage = () => {
    const lmbkStorage = _getLmbkStorage()
    if (lmbkStorage) {
        return lmbkStorage
    }
    else {
        return null
    }
}

export const token = () => {
    const lmbkStorage = _getLmbkStorage()
    if (lmbkStorage && lmbkStorage.token) {
        return lmbkStorage.token
    }
    else {
        console.warn("Warning: storage.js: Not Authenticated or Token Invalid")
        return null
    }
}

export const isUserLoaded = () => {
    const lmbkStorage = _getLmbkStorage()
    if(lmbkStorage && lmbkStorage.user_id){
        return true
    }else{
        return false
    }
}

export const user_id = () => {
    const lmbkStorage = _getLmbkStorage()
    if (lmbkStorage && lmbkStorage.user_id) {
        return lmbkStorage.user_id
    }
    else {
        return null
    }
  }