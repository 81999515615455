import React, { useState, useEffect } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import withRoot from '../../../../../../withRoot'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { setPermissions } from 'common/helpers/team'

const TogglePermissions = props => {
    const {team_config} = props
    const [dataAccess, setDataAccess] = useState(team_config.access)
    const [indDataAccess, setIndDataAccess] = useState(team_config.individual_access)

    useEffect(() => {
        if(dataAccess != team_config.access)
            setPermissions({team_id: team_config.team_id, access: dataAccess}).then((res) => {console.log(res)});
    }, [dataAccess])

    useEffect(() => {
        if(indDataAccess != team_config.individual_access)
            setPermissions({team_id: team_config.team_id, individual_access: indDataAccess}).then((res) => {console.log(res)});
    }, [indDataAccess])


    return(
        <Grid>
            <FormControlLabel
                value="bottom"
                control={<Switch color="primary" checked = {dataAccess} onClick = { () => setDataAccess(!dataAccess) } />}
                label="Data Access"
                labelPlacement="start"
            /> 
            <FormControlLabel
                value="bottom"
                control={<Switch color="primary" checked = {indDataAccess} onClick = { () => setIndDataAccess(!indDataAccess) } />}
                label="Individual Data Access"
                labelPlacement="start"
            /> 
        </Grid>
    )
}

export default withRoot(withStyles({}, { withTheme: true })(TogglePermissions))
