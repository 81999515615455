import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';
import {addUser, getTeam} from 'common/helpers/team'
import withRoot from 'root/withRoot'
import { Redirect } from 'react-router'
import Confirmation from 'common/components/Confirmation'
import Typography from '@material-ui/core/Typography'

// Material Components
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
    buttonContainer: {
        textAlign: 'center',
        paddingTop: '25%'
    },
    confirmed: {
        fontSize: 100,
        marginTop: '20px'
    },
    header: {
        backgroundColor: theme.palette.primary.main,
        width: '100%',
        padding: '8px'
    },
    description: {
        padding: '3%'
    }
})
const InviteConfirmation = props => {

    const {classes} = props
    const [loading, setLoading] = useState(true)
    const [team, setTeam] = useState(null)
    const [redirect, setRedirect] = useState(false)

    const search = window.location.search;
    const params = new URLSearchParams(search)
    const token = params.get('token')
    const team_id = params.get('team_id')

    useEffect(() => {
        getTeam(team_id).then((res) => {
            setTeam(res.data)
            setLoading(false)
        })
    }, [])

    const joinTeam = () => {
        addUser(team_id, token).then((res) => {
            // Redirect on success
            setRedirect(<Redirect to="/teams" />)
        })
    }

    if(redirect)
        return(redirect);

    return(        
        <Confirmation>
            { !loading ?
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Grid container className={classes.header} direction="column">
                                <Typography variant="h5" component="h3" align="center">
                                    Invitation to join {team.name}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.description}>
                                Other members of the team will be able to see that you are part of the team.
                                By default your data will not be included in the team average or shown individually, but you can enable both of these in the Teams tab in account settings.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className = {classes.buttonContainer}>
                                <Button color="secondary"  variant="contained" onClick = {() => joinTeam()}>Click To Join Team</Button>
                        </Grid>
                    </React.Fragment>
                    :
                    <Grid>Loading</Grid>
            }
        </Confirmation>
    )
}

export default withRoot(withStyles(styles, { withTheme: true })(InviteConfirmation))