import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import withRoot from '../../../../withRoot'

// Material Components
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'

// Internal Helpers
import { fetchGarminAccessKey } from 'common/helpers/garmin'

const styles = theme => ({
    container: {
        marginTop: '5%'
    },
    root: {
        margin: '32px',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    header: {
        backgroundColor: theme.palette.primary.main,
        width: '100%',
        padding: '8px',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px'
    },
    button: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginTop: '32px',
        marginBottom: theme.spacing(1),
        '&:hover': {
            backgroundColor: theme.palette.secondary.main
        }
    },
    menu: {
        width: 200,
    }
})

const devices = [
    {
        value: 'Garmin',
        label: 'Garmin',
    },
    {
        value: 'Apple',
        label: 'Apple',
    },
    {
        value: 'Samsung',
        label: 'Samsung',
    }
];

class RegistrationDeviceType extends Component {
    constructor(props) {
        super(props)
    }

    state = {
        device: '',
        accessKey: '',
    }

    static propTypes = {
        classes: PropTypes.object.isRequired,
        handleNext: PropTypes.func
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value })
    }

    handleRegisterDevice = () => {
        if (this.state.device === 'Garmin') {
            fetchGarminAccessKey()
            this.props.handleNext()
        }
    }

    render() {
        const { classes } = this.props
        return (
            <Grid container alignItems={'center'} justify={'center'} direction={'column'} className={classes.container}>
                <Grid item xs={12}>
                    <Paper className={classes.root} elevation={4}>
                        <Grid container className={classes.header} direction="column">
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h3" align="center">
                                    Device
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" align="center">
                                    choose device type below
                                </Typography>
                            </Grid>
                        </Grid>
                        <FormControl className={classes.root} noValidate autoComplete="off" component="fieldset">
                            <Grid container alignItems={'center'} justify={'center'} direction={'column'}>
                                <Grid item xs={12}>
                                    <TextField select id="device" label="device" className={classes.textField} value={this.state.device} onChange={this.handleChange('device')} margin="normal"
                                        SelectProps={{
                                            MenuProps: {
                                                className: classes.menu
                                            }
                                        }}
                                    >
                                        {devices.map(option => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Button variant="contained" color="primary" className={classes.button} onClick={this.handleRegisterDevice.bind(this)}>
                                    continue
                                </Button>
                            </Grid>
                        </FormControl>
                        {this.state.accessKey &&
                            <Grid container className={classes.header} direction="column">
                                <Grid item xs={12}>
                                    <Typography variant="subheading" align="center">
                                        Access Key
                                    </Typography>
                                    <Typography variant="body2" align="center">
                                        {this.state.accessKey}
                                    </Typography>
                                </Grid>
                            </Grid>
                        }
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}

export default withRoot(withStyles(styles, { withTheme: true })(RegistrationDeviceType))