import React, { useEffect, useState } from 'react'

// Teams
import DeleteTeam from './DeleteTeam';
import TogglePermissions from './TogglePermissions';
import Invite from './Invite';

// Material UI
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import withRoot from '../../../../../../withRoot'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  });

const Configuration = (props) => {
    const {config, team} = props
    return(
        <React.Fragment>
            <TableRow>
                <TableCell><DeleteTeam team = {team} /></TableCell>
                <TableCell><TogglePermissions team_config = {config[0]} key = {config[0].team_id} /></TableCell>
                <TableCell><Invite team = {team} /></TableCell>
            </TableRow>
        </React.Fragment>
    )

}

export default withRoot(withStyles(styles, { withTheme: true })(Configuration))
