import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withRoot from '../../../withRoot';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid';


const styles = theme => ({
    button: {
        fontSize: "75%",
        width: "180px",
    },
    link: {
        color: "#64ddf9"
    },
    snippet: {
        paddingTop:"3%"
    },
    text: {
        marginBottom: "1em"
    }
  })

const AccountSettingsPrivacy = (props) => {

    
    return (
        <Grid container alignItems="flex-start" direction="column" style={{paddingTop:"1%"}}>
        <Typography>
            We take your privacy and data very seriously.<br/>
            By using this Account, you agree to lmbk's <br/>
            <a className={props.classes.link} rel="noopener noreferrer" href="https://app.termly.io/document/terms-of-use-for-saas/2a89a30e-05e3-4dbd-aa1b-b38a726cbc60" target="_blank">Conditions for Use </a> 
            and <a className={props.classes.link} rel="noopener noreferrer" href="https://www.lmbk.io/privacy" target="_blank">Privacy Policy</a>
        </Typography>

        <Grid item className={props.classes.snippet}>
            <Typography className={props.classes.text}>
                You can request a download of your data at anytime
            </Typography>
            <Button className={props.classes.button} href="mailto:neuro@lmbk.io" size="large" variant="contained" color="secondary"  >
                DOWNLOAD DATA
            </Button>
        </Grid>

        <Grid item className={props.classes.snippet}>
            <Typography className={props.classes.text}>
                We'd hate to see you go. Drop us a line so we can make it right?
            </Typography>
            <Button className={props.classes.button} size="large" href="mailto:neuro@lmbk.io" variant="contained" color="secondary"  >
                LEAVE lmbk
            </Button>
        </Grid>

        </Grid>
    );
}

export default withRoot(withStyles(styles, { withTheme: true })(AccountSettingsPrivacy))
