import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withRoot from '../../../withRoot'
import { withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container';

// Internal Components
import AccountSettingsHead from '../components/AccountSettingsHead'
import AccountSettingsBar from '../components/AccountSettingsBar'

// Internal Helpers
import { fetchUserDetail } from 'common/helpers/account'
import { user_id } from 'common/helpers/storage'


const styles = theme => ({
    container: {
        [theme.breakpoints.down('md')]: {
            marginTop: "20%"
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: "5%"
        },
    }
})

const initialState = {
    firstName: null,
    lastName: null,
    username: null,
    email: null,
    date_joined: null,
    // role: null,
    // team: null,
    // device: null,
    loading: true
    
}

class AccountSettingsContainer extends Component {
    constructor(props) {
        super(props)
    }

    static propTypes = {
        classes: PropTypes.object.isRequired
    }

    state = initialState

    componentDidMount = () => {

        return fetchUserDetail(user_id())
        .then(res => {
            if (res) {
              this.setState({
                firstName: res.first_name || '',
                lastName: res.last_name || '',
                username: res.username || '',
                email: res.email || '',
                // role: res.role || '',
                // team: res.team || '',
                loading: false
                // date_joined: new Date(res.date_joined) || ''
              })
            }
        })
        .catch(e => console.log('fetchInfoError:', e))
    }

    render = () => {
        const { classes } = this.props
        return ( 
            <Container maxWidth="lg">
                <div className={classes.container}>
                    <AccountSettingsHead />
                    <AccountSettingsBar/>
                    
                    {/* {
                        (!this.state.loading) ?  
                            <AccountSettingsEdit className={classes} firstName={firstName} lastName={lastName} username={username} email={email} user_id = {user_id()} />
                        : 
                            <CircularProgress className= {classes.loading} />
                    } */}
                </div>
            </Container>
        )
    }
}


export default withRoot(withStyles(styles, { withTheme: true })(AccountSettingsContainer))