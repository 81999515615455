export const REQUEST_GARMIN_REQUEST_TOKEN = 'REQUEST_GARMIN_TOKEN '
export const REQUEST_GARMIN_AUTH_TOKEN = 'REQUEST_GARMIN_AUTH_TOKEN'
export const RECEIVE_GARMIN_REQUEST_TOKEN_SUCCESS = 'RECEIVE_AUTH_SUCCESS'
export const RECEIVE_GARMIN_REQUEST_ERROR = 'RECEIVE_AUTH_ERROR'
export const RECEIVE_GARMIN_AUTH_TOKEN_SUCCESS = 'RECEIVE_AUTH_SUCCESS'
export const RECEIVE_GARMIN_AUTH_TOKEN_ERROR = 'RECEIVE_AUTH_ERROR'

const _requestGarminRequestToken = (user) => {
    return {
        type: REQUEST_GARMIN_REQUEST_TOKEN,
        user
    }
}

const _receiveGarminRequestSuccess = (requestData) => {
    return {
        type: RECEIVE_GARMIN_REQUEST_TOKEN_SUCCESS,
        data: requestData,
        recievedAt: Date.now()
    }
}

const _receiveGarminRequestError = (error) => {
    return {
        type: RECEIVE_GARMIN_REQUEST_ERROR,
        error,
        recievedAt: Date.now()
    }
}

const _requestGarminAuthToken = (user) => {
    return {
        type: REQUEST_GARMIN_AUTH_TOKEN,
        user
    }
}

const _receiveGarminAuthSuccess = (authData) => {
    return {
        type: RECEIVE_GARMIN_AUTH_TOKEN_SUCCESS,
        data: authData,
        recievedAt: Date.now()
    }
}

const _receiveGarminAuthError = (error) => {
    return {
        type: RECEIVE_GARMIN_AUTH_TOKEN_ERROR,
        error,
        recievedAt: Date.now()
    }
}

export const fetchGarminRequest = (user, token) => {
    return (dispatch) => {
        dispatch(_requestGarminRequestToken(user, token))
        return api.get()
            .then((authData) => {
                dispatch(_receiveGarminRequestSuccess(authData))
                return authData
            })
            .catch(() => {
                dispatch(_receiveGarminRequestError(error))
                throw error
            })
    }
}

export const fetchGarminAuth = (user, token) => {
    debugger
    return (dispatch) => {
        dispatch(_requestGarminAuthToken(user, token))
        return api.get(api_url + '/user/${user.id}/garmin/')
            .then((authData) => {
                dispatch(_receiveGarminAuthSuccess(authData))
                return authData
            })
            .catch(() => {
                dispatch(_receiveGarminAuthError(error))
                throw error
            })
    }
}
