import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import withRoot from '../../../withRoot'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import LmbkIcon from '../../assets/LmbkIcon'

import SpacedTypography from 'common/components/SpacedTypography'

const styles = theme => ({
    container: {
        marginTop: '10%',

    },
    root: {
        flexGrow: 1,
        margin: '32px',
        display: 'flex',
        flexDirection: 'column',
    },
    button: {
        margin: theme.spacing(1),
        
    }
})


class LandingPage extends Component {
    constructor(props) {
        super(props)
    }

    static propTypes = {
        classes: PropTypes.object.isRequired
    }

    componentDidMount () {
        const script = document.createElement("script");
    
        script.src = "//js.hs-scripts.com/6691217.js";
        script.async = true;
        script.id = "hs-script-loader";
    
        document.body.appendChild(script);
    }

    render() {
        const LoginLink = props => <Link to="/login" {...props} />
        const RegistrationLink = props => <Link to="/registration" {...props} />
        const { classes } = this.props
        return (
            <Grid container className={classes.container}   justify={'center'} alignItems={'center'} direction={'column'}>
            <Grid item  className={classes.root} xs={12}>
                <Grid container  justify={'center'} alignItems={'center'} direction={'column'}>
                    <Grid item xs={12}>
                        <LmbkIcon color="primary" style={{ fontSize: 180 }} />
                    </Grid>
                </Grid>
                <Grid container justify={'center'} alignItems={'center'} direction={'column'}>
                    <Grid item xs={12}>
                        <SpacedTypography variant="h2" gutterBottom>
                            Welcome to lmbk
                        </SpacedTypography>
                    </Grid>
                </Grid>
                <Grid container spacing={1} justify={'center'} alignItems={'center'} direction={'column'}>
                    <Grid item xs={16}>
                        <Button size="large" variant="contained" color="primary" className={classes.button} component={RegistrationLink}>
                            Signup
                        </Button>
                        <Button size="large" variant="contained" color="secondary" className={classes.button} component={LoginLink}>
                            Login
                        </Button>
                    </Grid>
                </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default withRoot(withStyles(styles, { withTheme: true })(LandingPage))
