import React, { useState, Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
// Material UI
import { withStyles } from '@material-ui/core/styles'
import withRoot from '../../../withRoot'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Tooltip from '@material-ui/core/Tooltip'
import Modal from '@material-ui/core/Modal'
import AssessmentIcon from '@material-ui/icons/Assessment'
import AssignmentIcon from '@material-ui/icons/Assignment'
import FaceIcon from '@material-ui/icons/Face'
import SpaIcon from '@material-ui/icons/Spa'
import LogoutIcon from '@material-ui/icons/PowerSettingsNew'
import TeamIcon from '@material-ui/icons/Group'
import { processLogout } from 'common/helpers/auth'
import { token } from 'common/helpers/storage'
import classNames from 'classnames'

// Material Colors
import grey from '@material-ui/core/colors/grey'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

// This really should be done with CSS, but it's the fastest ok way to get this responsive
import { useMediaPredicate } from 'react-media-hook';
import SimpleModal from 'common/components/SimpleModal'
import CardMedia from '@material-ui/core/CardMedia'
import LmbkIcon from '../../assets/LmbkIcon'
import Drawer from '@material-ui/core/Drawer'

import { isAuthenticated } from 'common/helpers/auth'

const drawerWidth = 240

const styles = theme => ({
    root: {
    },
    topBar: {
        width: '100%',
        backgroundColor: grey[800],
        position: 'fixed',
        marginBottom: '10%',
    },
    listItem: {
    },
    listItemLogout: {
        marginTop: '0%' 
    },
    iconHover: {
        color: theme.palette.primary.main,
        '&:hover': {
            color: '#00ffa3'
        }
    },
    menuButton: {
        marginLeft: 24,
    },
    hide: {
        display: 'none',
    },
    lmkbLogo: {
        path: {
            '.cls-1': {
                fill: '#64ddf9'
            },
            '.cls-2': {
                fill: '#00ffa3'
            }
        }
    },
    drawerPaper: {
        backgroundColor: grey[800],
        position: 'fixed',
        whiteSpace: 'nowrap',
        height: '100%',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
        
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing( 7),
        height: '100%',
        [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
        },
    },

})

const Sidebar = (props) => {

    const [menuOpen, setMenuOpen] = useState(false)
    const [openDestressorsModal, DestressorsModal] = useState(false)
    const [openSurveyModal, SurveyModal] = useState(false)
    const [open, setOpen] = useState(false)
    const { classes } = props


    const propTypes = {
        isAuthed: PropTypes.bool.isRequired,
        classes: PropTypes.object.isRequired
    }

    const toggleDestressorsModal = () => {
        DestressorsModal(!openDestressorsModal)
    }

    const toggleSurveyModal = () => {
        SurveyModal(!openSurveyModal)
    }

    const getNavigationItems = () => {
        const isAuthed = isAuthenticated()
        const { classes } = props
        if (isAuthed) {
            return (
                <Drawer
                variant="permanent"
                classes={{
                    paper: classNames(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
                >

                    <List className={classes.root} style={{margin: "10%"}}>

                        <ListItem button classes={{ gutters: classes.listItem }}>
                                <ListItemIcon>
                                    <LmbkIcon color="primary" className={classes.iconHover} />
                                </ListItemIcon>
                            <ListItemText primary="Dashboard" primaryTypographyProps={{color: 'primary', variant: 'body2'}}/>
                        </ListItem>

                        <Divider />
                        <ListItem button classes={{ gutters: classes.listItem }} component={Link} to="/cortex">
                            <Tooltip id="tooltip-icon" title="Dashboard" placement="right">
                                <ListItemIcon>
                                    <AssessmentIcon color="primary" className={classes.iconHover} />
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary="Dashboard" primaryTypographyProps={{color: 'primary', variant: 'body2'}}/>
                        </ListItem>
                        <ListItem button classes={{ gutters: classes.listItem }} component={Link} to='/teams'>
                                <Tooltip id="tooltip-icon" title="Team" placement="right">
                                    <ListItemIcon>
                                        <TeamIcon color="primary" className={classes.iconHover} />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary="Team" primaryTypographyProps={{color: 'primary', variant: 'body2'}}/>
                        </ListItem>
                        <ListItem button classes={{ gutters: classes.listItem }} onClick={toggleDestressorsModal}>
                            <Tooltip id="tooltip-icon" title="Destressors" placement="right">
                                <ListItemIcon>
                                    <SpaIcon color="primary" className={classes.iconHover} />
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary="Destressors" primaryTypographyProps={{color: 'primary', variant: 'body2'}}/>
                            <SimpleModal title={'Destressors'} description={'What things make you feel less stressed? This is tied into our Ai analytics to better learn about you.'} open={openDestressorsModal}>
                            <CardMedia
                                component="img"
                                image="https://s3.us-east-2.amazonaws.com/lmbk-assets/feature-assets/destressors_modal.png">
                                </CardMedia>
                            </SimpleModal>
                        </ListItem>
                        <ListItem  button classes={{ gutters: classes.listItem }} component={Link} to='/survey'>
                            <Tooltip id="tooltip-icon" title="Surveys" placement="right">
                                <ListItemIcon>
                                    <AssignmentIcon color="primary" className={classes.iconHover} />
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary="Survey" primaryTypographyProps={{color: 'primary', variant: 'body2'}} />
                        </ListItem>
                        <ListItem button classes={{ gutters: classes.listItem }} component={Link} to="/accounts">
                            <Tooltip id="tooltip-icon" title="Account Settings" placement="right">
                                <ListItemIcon>
                                    <FaceIcon color="primary" className={classes.iconHover} />
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary="Account Settings" primaryTypographyProps={{color: 'primary', variant: 'body2'}}/>
                        </ListItem>
                        {   props.isAuthed &&
                            <ListItem button className={ classes.listItemLogout }>
                                <Tooltip id="tooltip-icon" title="Logout" placement="right">
                                    <ListItemIcon onClick={processLogout}>
                                        <LogoutIcon color="primary" className={classes.iconHover} />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary="Logout" primaryTypographyProps={{color: 'primary', variant: 'body2'}} />
                            </ListItem>
                        }
                    </List>
                </Drawer>
            )
        }
    }

    const mediaCheck = useMediaPredicate(
        '(min-width: 960px)'
      );

    const displaySidebar = () => {
        return (
            <Grid item xs={1}>
                <Grid container className={classes.root} direction="row">
                    <Grid item xs={12}>
                        {getNavigationItems()}
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    function handleClick(event) {
        setMenuOpen(event.currentTarget);
    }
    
    function handleClose() {
        setMenuOpen(false);
    }

    const displayResponsiveMenu = () => {
        const isAuthed = token() ? true : false
        return( <div className={classes.topBar} style = {{textAlign: "center"}} >
            <Button 
            aria-controls="simple-menu" 
            aria-haspopup="true" 
            onClick={handleClick}
            >
                <LmbkIcon color="primary" className={classes.lmbkLogo} style={{ fontSize: 46 }} />
            </Button>
            { isAuthed &&
                <Menu
                    keepMounted
                    open={Boolean(menuOpen)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleClose}><Link to="/cortex">
                        <AssessmentIcon color="primary" className={`${classes.iconHover} ${classes.responsiveIcon}`} />
                        </Link></MenuItem>
                    <MenuItem>
                        <Link to="/teams">
                            <TeamIcon color="primary" className={`${classes.iconHover} ${classes.responsiveIcon}`} />
                        </Link>
                    </MenuItem>
                    <MenuItem onClick={toggleDestressorsModal}>
                        <SpaIcon color="primary" className={`${classes.iconHover} ${classes.responsiveIcon}`} />
                    </MenuItem>
                    <MenuItem >
                        <Link to="/survey">
                            <AssignmentIcon color="primary" className={`${classes.iconHover} ${classes.responsiveIcon}`} />
                        </Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to="/accounts">
                            <FaceIcon color="primary" className={`${classes.iconHover} ${classes.responsiveIcon}`} />
                        </Link>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <Link to="/logout">
                            <LogoutIcon color="primary" className={`${classes.iconHover} ${classes.responsiveIcon}`} />
                        </Link>
                    </MenuItem>
                </Menu>
            }
        </div>)
    }

    const variableRender = () => {
        return mediaCheck ? displaySidebar() : displayResponsiveMenu()
    }

    return(variableRender())
}

export default withRoot(withStyles(styles, {withTheme: true})(Sidebar))
