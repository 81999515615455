import React from 'react'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Button } from '@material-ui/core';

const TeamRow = (props) => {
    const { team, setTeam, index } = props

    return(
    <TableRow>
        <TableCell>{team.name}</TableCell>
        <TableCell>{team.team_count}</TableCell>
        <TableCell>{(team.lsi_avg/10).toFixed(2)}</TableCell>
        <TableCell><Button onClick = {() => setTeam(index)}>View</Button></TableCell>
    </TableRow>
    )
}

export default TeamRow;
