import React, { useState, useContext } from 'react'
import { withStyles } from '@material-ui/core/styles'
import withRoot from '../../../withRoot'

// Material Components
import Grid from '@material-ui/core/Grid'
import { syncData } from 'common/helpers/garmin';
import {NotificationContext} from 'common/context/Notifications'

// Material UI
import { Button } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
})

const SyncButton = props => {
    const notifications = useContext(NotificationContext)
    const [loading, setLoading] = useState(false)

    const sync = () => {
        setLoading(true)
        syncData().then((res) => {
            if(res.data.message == "Success"){
                console.log(res)
                notifications.notify("Your device has been synced!")
            }else{
                // notifications.notify(res.data.message)
            }
        }).catch((e) => {
            notifications.notify("Hm, we weren't able to sync your account, please reach out to our support if you still experience this issue")
        }).finally(() => {
            setLoading(false)
        })
    }

    return(
        <React.Fragment>
            {
                loading ?
                <LinearProgress />
                :
                <Grid container direction="column">
                    <Button onClick = {sync}>Sync Data</Button>
                </Grid>
            }
        </React.Fragment>
    )

}

export default withRoot(withStyles(styles, { withTheme: true })(SyncButton))
