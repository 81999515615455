import React from "react"
import ReactDOM from "react-dom"
import { Provider } from 'react-redux'
import AppContainer from 'core/containers/AppContainer'
import { createBrowserHistory } from 'history'
import reducers from 'core/reducers/root'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware, ConnectedRouter } from 'connected-react-router'
import HttpsRedirect from 'react-https-redirect';
import { NotificationProvider } from 'common/context/Notifications'

const history = createBrowserHistory()
const initialState = {}

const store = createStore(
    reducers(history),
    initialState,
    compose(
        applyMiddleware(
            routerMiddleware(history)
        ),
    ),
)

const App = () => {
    return (
        <HttpsRedirect>
            <Provider store={store}>
                <NotificationProvider >
                    <ConnectedRouter history={history}>
                        <AppContainer history = {history}/>
                    </ConnectedRouter>
                </NotificationProvider>
            </Provider>
        </HttpsRedirect>
    )
}

ReactDOM.render(<App />, document.getElementById("root"))