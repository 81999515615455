import React, { useState, Component, Fragment, useEffect } from 'react'
import withRoot from '../../../withRoot'
import { withStyles } from '@material-ui/core/styles'
import { LSILineGraph } from 'common/components/graphs/LSILineGraph'
import { lsiHistoryToGraph } from 'common/helpers/lsi'
import {
  LineSeries,
} from 'react-vis'
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
import {curveCatmullRom} from 'd3-shape';

const styles = theme => ({
  circle: {
    height: '20px',
    width: '20px',
    borderRadius: '50%'
  },
  textAlignLeft: {
    textAlign: 'left'
  },
  keyItem: {
    padding: '10px'
  }
})


const GraphContainer = (props) => {
  const {classes, timePeriod, teamData, timeRange} = props

  const createGraphData = (lsi) => {
    return lsi.lsi_hist.map(lsiHistoryToGraph)
  }

  const renderIndividuals = () => {
    return(teamData.individual_users.map(user => {
      return(
          <LineSeries
        data={createGraphData(user)}
        color={`${user.team_color}`}
        key = {user}
        opacity={.9}
        curve={curveCatmullRom.alpha(1)}
          />
      )
    }))
  }

  const TeamLegend = () => (<Grid className = {classes.keyItem} container item xs={3}>
			    <Grid xs={3}>
			    <div className = {classes.circle} style = {{backgroundColor: '#64DDF9'}}></div>
			    </Grid>
			    <Grid className={classes.textAlignLeft} xs = {9}>Team Stress Data</Grid>
			    </Grid>)

  const TeamMemberLegend = () => {
    return teamData.individual_users.map(user => {
      return(
          <Grid className={classes.keyItem} container item xs={3}>
          <Grid xs={3}>
          <div className={classes.circle} style={{backgroundColor: `${user.team_color}`}}></div>
          </Grid>
          <Grid className={classes.textAlignLeft} xs={9}>{user.name}</Grid>
          </Grid>
      )
    })
  }

  return teamData.individual_users ?
    (<Grid container spacing={3}>
     <Grid item xs = {12}>
     <LSILineGraph timePeriod={timePeriod} lsiHistory={createGraphData(teamData)} timeRange={timeRange}>
     {renderIndividuals()}
     </LSILineGraph>
     </Grid>
     <Grid container item xs={12} direction="row" alignItems="center" justify="center">
     <TeamLegend/>
     </Grid>
     <Grid container item xs={12} direction="row" alignItems="center" justify="center">
     <TeamMemberLegend/>
     </Grid>
     </Grid>)
    : (<div></div>)
}

export default withRoot(withStyles(styles, {withTheme: true})(GraphContainer))
