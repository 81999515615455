import React, { useState, useEffect, useContext } from 'react'
import withRoot from '../../../../withRoot'

// Team
import { getTeams, callCreateTeam } from 'common/helpers/team'
import Teams from './Teams'
import { TeamsContext } from './context/TeamsContext'


import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { user_id } from 'common/helpers/storage';
import { fetchUserDetail } from 'common/helpers/account'
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid'

const styles = theme => ({
    title: {
        fontSize: 14,
        paddingBottom: '10%'
      },
      button: {
        fontSize: "75%",
        width: "180px",
        marginBottom: "90px"
    }
  });

const TeamConfiguration = props => {
    const { classes } = props
    const [loading, setLoading] = useState(true)
    const [teams, setTeams] = useContext(TeamsContext)
    const [user, setUser] = useState(false)
    const [teamName, setTeamName] = useState("")
    
    // Get The Teams
    useEffect(() => {
        async function init() {
            let userData = await fetchUserDetail(user_id())
            let teamData = await getTeams()
            // let [teamData, userData] = await Promise.all([fetchUserDetail(user_id()), getTeams()])
            setTeams(teamData.data)
            setUser(userData)
            setLoading(false)
        }
        init()
    }, [])

    const createTeam = () => {
        callCreateTeam(teamName).then((res) => {
            setTeams(teams.concat([res.data]))
        })
    }

    return(
        <Grid container>
            <Grid item xs = {12}>

                <Grid item xs = {12}>
                    <Typography className = {classes.title}>Create Team</Typography>
                        <TextField
                            id="teamname"
                            label="Team Name"
                            className={classes.textField}
                            value={teamName}
                            onChange={(e) => setTeamName(e.target.value)}
                            margin="normal"
                            color= '#64ddf9'
                        />
                    </Grid>
                    <Grid item xs = {12}>
                        <Button size="large" variant="contained" color="secondary" className = {classes.button} onClick = { () => createTeam() }>Create Team</Button>
                    </Grid>

                </Grid>
            <Grid item xs = {12}>
                <Grid item xs = {12}>
                    { !loading ?
                        <Teams teams = {teams} user = {user} setTeams = {setTeams} />
                        :
                        <CircularProgress />
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default withRoot(withStyles(styles, { withTheme: true })(TeamConfiguration))
