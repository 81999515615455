import React, { useReducer, useContext } from 'react'
import { UserSettingsContext, UserSettingsProvider } from 'common/context/UserSettings'



export const UserContext = React.createContext({});

const UserContextContainer = props => {
    const user_settings = useContext(UserSettingsContext)
    let user = {
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        user_settings: user_settings.state
    }
    
    const userReducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_USER':
                return {[action.payload.key]: action.payload.value};
            case 'UPDATE_USER_SETTINGS':
                return user_settings.dispatch({type: 'UPDATE', payload: action.payload});
        }
    }
    const [state, dispatch] = useReducer(userReducer, user);

    return(
        <UserContext.Provider value = { { user: state, dispatch: dispatch } }>
            { props.children }
        </UserContext.Provider>
    )
}

export const UserProvider = props => {

    return(
        <UserSettingsProvider>
            <UserContextContainer>
                { props.children }
            </UserContextContainer>
        </UserSettingsProvider>
    )

}