import React, { useState, useContext }from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../../../withRoot';
import { postReset } from 'core/reset/reset'; 
import InputCard  from 'common/components/InputCard'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'


import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Input } from '@material-ui/core';
import { NotificationContext } from 'common/context/Notifications';

// I amm not DRY uck. this needs to change
const styles = theme => ({
    root:{
        display: 'flex',
        flexDirection: 'column',
        margin: '32px',
    },
    input: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(3),
        width: 200,
    },
    header: {
      marginLeft: "2%",
      // fontSize: '145%'
    },
    button: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginTop: '32px',
        marginBottom: theme.spacing(1),
        '&:hover': {
            backgroundColor: theme.palette.secondary.main
        }
    },
})


/****
 * Component will take an email
 * email will be sent to backnd to send email for user
 * email button will link to update pasword page
 * ****/
const RecoverPageContainer = props => {
  const { classes } = props
  const [email, setEmail] = useState('');
  const notification = useContext(NotificationContext)
  
	
	const handleNameChange = (e) => {     
		setEmail(e.target.value) 
		}
	
	const handleSubmit = async (e) => {
    try{
      let response = await postReset(email)
      notification.notify(response.data)
    }catch(err){
      notification.notify(err.response.data)
    }
	}

    return (
        <InputCard
          header = {
            <Typography variant="h5" align="center" className = {classes.header} >Send password reset email</Typography>
          }
          body = {
              <Grid className = {classes.root}>
                  <Grid >
                    <TextField
                      className = {classes.input}
                      placeholder="Email" 
                      value={email}          
                      onChange={handleNameChange}
                    />          
                  </Grid>
                <Button variant="contained" color="primary" onClick = {handleSubmit} className = {classes.button} >Submit</Button>
              </Grid>
          }
        />

    )
}

export default withRoot(withStyles(styles, { withTheme: true })(RecoverPageContainer))