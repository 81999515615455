import config from 'app/config'
import api from 'common/helpers/api'
import moment from 'moment'

const _getLSI = () => {
    api.get(`${config.API_URL}/lsi/`)
        .then((res) => {
            return res.data
        })
        .catch((e) => {
            console.error("Error: ", e)
        })
}

const _getTeamLSI = () => {
  return api.get(`${config.API_URL}/team/lsi/all/`)
}

const _getLSIHistory = async (dates) => {
  const { startDate, endDate } = dates
  const data = {
    start_date: startDate.format(),
    end_date: endDate.format()
  }
  try {
    return {
      lsiHistory: await api.post(`${config.API_URL}/lsi/history/`, data),
      lsiStartDate: startDate.toDate(),
      lsiEndDate: endDate.toDate()
    }
  } catch (error) {
    console.error("Error: ", error)
  }
  return response
}

// api request for the team lsi data
export const fetchTeamLSI = () => {
  return _getTeamLSI()
}


export const lsiHistoryToGraph = lsiHistoryData => {
  return {
    x: moment(lsiHistoryData.recorded_at || lsiHistoryData.day).unix(),
    y: lsiHistoryData.stress_level / 10
  }
}

// api request for an object for lsi
export const fetchLSI = () => {
    return _getLSI()
}

// api request for an array of lsiHistory objects
export const fetchLSIHistory = (interval) => {
    return _getLSIHistory(interval)
}
