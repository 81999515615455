import React, {ReactNode, SyntheticEvent} from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import withRoot from '../../../withRoot'

// Google Api
// commenting out since the credentials that were in the
// repo were removed for security purposes
// import ApiCalendar from 'react-google-calendar-api'

// Material UI
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

// Styles
const styles = theme => ({
    root: {
        flexGrow: 1
    },
    tab: {
        backgroundColor: theme.palette.background.paper
    }
})

class GoogleButtons extends React.Component {
    constructor(props) {
        super(props)
        this.handleItemClick = this.handleItemClick.bind(this)
    }

    static propTypes = {
        classes: PropTypes.object.isRequired
    }

    handleItemClick = (event, name) => {
        // if (name === 'sign-in') {
        //   ApiCalendar.handleAuthClick()
        // } else if (name === 'sign-out') {
        //   ApiCalendar.handleSignoutClick()
        // }
    }

    getGoogleCalendarEvents = () => {
        // if (ApiCalendar.sign)
        //     ApiCalendar.listUpcomingEvents(10)
        //     .then((result) => {
        //         return result.items
        //     }).catch(e => console.log(e))
    }

    render = () => {
      const { classes } = this.props
      return (
          <Grid container className={ classes.root } direction="row">
            <Grid item xs={12} justify="center">
                <Grid container justify="center">
                    <Grid item xs={2} justify="center">
                        <Button onClick={(e) => this.handleItemClick(e, 'sign-in')}>sign in</Button>
                    </Grid>
                    <Grid item xs={2} justify="center">
                        <Button onClick={(e) => this.handleItemClick(e, 'sign-out')}>sign-out</Button>
                    </Grid>
                </Grid>
            </Grid>
          </Grid>
      )
    }
}

export default withRoot(withStyles(styles, { withTheme: true })(GoogleButtons))
