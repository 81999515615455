import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import withRoot from '../../../withRoot'
import GoogleButton from 'dashboard/components/GoogleCalendar'

// Material Components
import Grid from '@material-ui/core/Grid'

// Material Colors
import red from '@material-ui/core/colors/red'
import orange from '@material-ui/core/colors/orange'
import teal from '@material-ui/core/colors/teal'
import grey from '@material-ui/core/colors/grey'

// Material Icons
import InformationIcon from '@material-ui/icons/Info'

// Internal Components
import DashboardStats from 'dashboard/components/DashboardStats'
import SyncButton from 'dashboard/components/SyncButton'
const styles = theme => ({
    card: {
        minWidth: 150,
    }
    
})

class Dashboard extends Component {
    constructor(props) {
        super(props)
    }

    static propTypes = {
        classes: PropTypes.object.isRequired
    }

    render() {
        const { classes } = this.props
        return (
            <Grid container direction="column">
                <Grid item xs>
                    {/* <GoogleButton /> */}
                    <SyncButton/>
                </Grid>
                <Grid item xs>
                    <DashboardStats />
                </Grid>
            </Grid>
        )
    }
}

export default withRoot(withStyles(styles, { withTheme: true })(Dashboard))
