import React, { useEffect, useState } from 'react'


// Teams
import Configuration from './configuration/Configuration'

// Material UI
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import withRoot from '../../../../../withRoot'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  });


const Members = (props) => {
    const {team, user} = props
    const [members, setMembers] = useState(null)
    const [config, setConfig] = useState(null)
    
    useEffect(() => {
        let team_config = null
        setMembers(team.members.map((member) => {
            if(member.user.username == user.username)
                team_config = member.team_configs.filter(t => t.team_id == team.id);
            return(
                <TableRow key = {member.user.username}>
                    <TableCell>
                        <Typography>{member.user.username}</Typography>
                    </TableCell>
                </TableRow>)
        }))
        setConfig(team_config)
    }, [])

    return(
        <React.Fragment>
            {config && <Configuration config = {config} team = {team} />}
            <TableRow><h3>Members</h3></TableRow>
            {members}
        </React.Fragment>
    )
} 

export default withRoot(withStyles(styles, { withTheme: true })(Members))
