import Immutable from 'immutable'
import {
    REQUEST_AUTH_LOGIN,
    REQUEST_AUTH_LOGOUT,
    RECEIVE_AUTH_SUCCESS,
    RECEIVE_AUTH_ERROR
} from 'core/actions/auth'

const defaultAuthState = Immutable.fromJS({
    authenticated: false,
    error: '',
    data: {}
})

const auth = (state = defaultAuthState, action) => {
    switch (action.type) {
        case REQUEST_AUTH_LOGIN:
            return state.withMutations(map => {
                map.set('authenticated', true)
            })
        case RECEIVE_AUTH_SUCCESS:
            return state.withMutations(map => {
                map.set('data', action.data)
            })
        case RECEIVE_AUTH_ERROR:
            return state.withMutations(map => {
                map.set('error', action.error)
            })
        default:
            return state
    }
}

export default auth