import  React, { Component } from "react"
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import withRoot from '../../../../withRoot'
import { watchServer, isEmailActivated, resendActivationEmail } from 'common/helpers/auth'
import { NotificationContext } from 'common/context/Notifications'


// Material Components
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'


const styles = theme => ({
    root: {
    },
    container: {
        marginTop: '5%',
        overflow: 'hidden'
    },
    button: {
        '&:hover': {
            backgroundColor: theme.palette.secondary.main
        }
    },
    buttonContainer: {
        textAlign: 'center',
        padding: '5%'
    },
    header: {
        backgroundColor: theme.palette.primary.main,
        width: '100%',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px'
    }
})

class ActivateEmailPage extends Component {
    constructor(props) {
        super(props)
        // Get a websocket connection to watch if the user activates there email
        var ws = watchServer(this.onmessage)
        ws.onmessage = this.onmessage
    }

    static propTypes = {
        classes: PropTypes.object.isRequired,
        handleNext: PropTypes.func.isRequired
    }

    state = {
        terms: false,
        isSaving: false
    }

    onmessage = (e) => {
        var email_activated = JSON.parse(JSON.parse(e.data).text).email_activated
        if(email_activated){
            this.props.handleNext()
        }
    }

    isEmailActivated = () => {
        isEmailActivated().then(res => {
            if(res){
                this.props.handleNext()
            }
        })
    }

    resendActivationEmail = () => {
        resendActivationEmail()
        this.context.notify('A verification link has been sent to your email to verify your account')
    }

    render() {
        const { classes } = this.props
        return (
            <Grid container alignItems={'stretch'} justify={'center'} direction={'column'} className={classes.container}>
                <Grid item xs={12}>
                    <Paper className={classes.root} elevation={4}>
                        <Grid container className={classes.header} direction="column">
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h3" align="center">
                                    Welcome to lmbk
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs = {12} className = {classes.buttonContainer}>
                            <FormControl className={classes.root} noValidate autoComplete="off" component="fieldset">
                                <Button variant="contained" color="primary" className={classes.button} disabled={this.state.isSaving} onClick={this.resendActivationEmail.bind(this)}>
                                    Resend Activation Email
                                </Button>
                            </FormControl>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}

ActivateEmailPage.contextType = NotificationContext;

export default withRoot(withStyles(styles, { withTheme: true })(ActivateEmailPage))
