import { combineReducers } from 'redux'
import Immutable from 'immutable'

import {
    REQUEST_GARMIN_REQUEST_TOKEN,
    REQUEST_GARMIN_AUTH_TOKEN,
    RECEIVE_GARMIN_REQUEST_TOKEN_SUCCESS,
    RECEIVE_GARMIN_REQUEST_ERROR,
    RECEIVE_GARMIN_AUTH_TOKEN_SUCCESS,
    RECEIVE_GARMIN_AUTH_TOKEN_ERROR
} from 'core/actions/garmin'

const defaultGarminState = Immutable.fromJS({
    garmin: {},
    isFetching: true,
    error: ''
})

const garmin = (state = defaultGarminState, action) => {
    switch (action.type) {
        case REQUEST_GARMIN_REQUEST_TOKEN:
            return state.withMutations(map => {
                map.set('isFetching', true)
                map.set('error', '')
            })
        case RECEIVE_GARMIN_REQUEST_TOKEN_SUCCESS:
            return state.withMutations(map => {
                map.set('isFetching', false)
            })
        case RECEIVE_GARMIN_REQUEST_ERROR:
            return state.withMutations(map => {
                map.set('isFetching', false)
                map.set('error', action.error)
            })
        case REQUEST_GARMIN_AUTH_TOKEN:
            return state.withMutations(map => {
                map.set('isFetching', true)
                map.set('error', '')
            })
        case RECEIVE_GARMIN_AUTH_TOKEN_SUCCESS:
            return state.withMutations(map => {
                map.set('isFetching', false)
            })
        case RECEIVE_GARMIN_AUTH_TOKEN_ERROR:
            return state.withMutations(map => {
                map.set('isFetching', false)
                map.set('error', action.error)
            })
        default:
            return state
    }
}

export default garmin
