import React from 'react'
import withRoot from '../../withRoot'
import { withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container';
import { ReactTypeformEmbed } from 'react-typeform-embed';

const styles = theme => ({
})

const SurveyForm = (props) => {
    const {url, width, height, submit} = props
    return(
        <Container maxWidth="lg">
            <ReactTypeformEmbed 
                url={url}
                style={ {
                    width: width,
                    height: height
                } }  
                hideFooter={false}
                hideHeaders={false}
                onSubmit={submit}
                opacity={0}
            />          
        </Container>
    )
}

export default withRoot(withStyles(styles, {withTheme: true})(SurveyForm))
