import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import withRoot from '../../../withRoot'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { Redirect } from 'react-router'
import { processLogin } from 'common/helpers/auth'
import { NotificationContext } from 'common/context/Notifications'
import InputCard  from 'common/components/InputCard'
import Link from '@material-ui/core/Link';

const styles = theme => ({
    typography: {
        color: '#00ffa3',
    },  
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    button: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginTop: '32px',
        marginBottom: theme.spacing(1),
        '&:hover': {
            backgroundColor: theme.palette.secondary.main
        }
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: '32px',
    },
    forgot:{
        fontSize: '75%',
        marginLeft: theme.spacing(13),
    }
})

class LoginPage extends Component {
    constructor(props) {
        super(props)
    }

    state = {
        username_or_email: '',
        password: '',
        redirectToReferrer: false
    }

    static propTypes = {
        classes: PropTypes.object.isRequired
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value })
    }

    handleLogin = async () => {
        const credentials = {
            username_or_email: this.state.username_or_email,
            password: this.state.password
        }

        try{
            let response = await processLogin(credentials)
            console.log("Update State", response)
            
            this.setState({
                redirectToReferrer: true
              })
            
        }catch(e) {
            console.error("Error: ", e.response.data.error)
            this.context.notify(e.response.data.error)
        }
    }

    componentDidMount () {
        const script = document.createElement("script");
    
        script.src = "//js.hs-scripts.com/6691217.js";
        script.async = true;
        script.id = "hs-script-loader";
    
        document.body.appendChild(script);
    }

    getLoginContent = () => {
        const { classes } = this.props
        return(
            <InputCard
                header = {
                    <React.Fragment>
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h3" align="center">
                                    Welcome back!
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" align="center">
                                    login below
                                </Typography>
                            </Grid>
                    </React.Fragment>
                }
                body = {
                    <form className={classes.root} noValidate autoComplete="off">
                        <TextField
                            required
                            id="email"
                            label="email or username"
                            className={classes.textField}
                            value={this.state.email}
                            onChange={this.handleChange('username_or_email')}
                            margin="normal"
                        />
                        <TextField
                            required
                            id="password"
                            label="password"
                            className={classes.textField}
                            value={this.state.password}
                            type="password"
                            onChange={this.handleChange('password')}
                            margin="normal"
                        />

                        <Grid item xs={12} className={classes.typography}>
                            <Typography variant="body1" align="center" >
                                <Link href="/recover" color="inherit" className = {classes.forgot}>
                                    {'forgot password?'}
                                </Link>
                            </Typography>
                        </Grid>

                        <Button variant="contained" color="primary" className={classes.button} onClick={this.handleLogin.bind(this)}>
                            login
                        </Button>
                    </form>
                }
            />       
        )
    }

    render() {
        const { from } = this.props.location.state || { from: { pathname: '/cortex' } }
        const { redirectToReferrer } = this.state
        if (redirectToReferrer === true) {
            return <Redirect to={from} />
          }
        return this.getLoginContent()
    }
}

LoginPage.contextType = NotificationContext;


export default withRoot(withStyles(styles, { withTheme: true })(LoginPage))
