import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Material Components
import Typography from '@material-ui/core/Typography'

class SpacedTypography extends Component {

  constructor(props) {
    super(props)
  }

  static propTypes = {
    variant: PropTypes.string.isRequired,
    children: PropTypes.node
  }


  render() {
    const { children, ...otherProps } = this.props

    return (
      <div style={{ margin: "1.5em", color:"#424242" }} >
        <Typography {...otherProps} >
          { children }
        </Typography>
      </div>
    )
  }
}

export default SpacedTypography
