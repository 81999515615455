export const isValidLSIRange = (value) => {
  // check if no value
  if (!value) return false
  // check if not a number
  if (isNaN(value)) return false
  // check to see if value is within the valid lsi range
  if ( value > 10 || value < 0) return false

  return true
}

export const isLsiLow = (value)  => {
  if (isValidLSIRange(value)) return value <= 4.0 ? true : false
  return false
}

export const isLsiMedium= (value)  => {
  if (isValidLSIRange(value)) return (value > 4.0 && value <= 7.0) ? true : false
  return false
}

export const isLsiHigh = (value)  => {
  if (isValidLSIRange(value)) return (value > 7.0) ? true : false
  return false
}

export const getMinAvgMaxLsi = (lsiValues) => {
  let minLsi = Math.min(...lsiValues)
  minLsi = isValidLSIRange(minLsi) ?  minLsi : null

  let maxLsi = Math.max(...lsiValues)
  maxLsi = isValidLSIRange(maxLsi) ?  maxLsi : null

  let totalLsi = lsiValues.reduce((sum, nextLsi) => {
    return sum + Number(nextLsi)
  }, 0)

  let avgLsi = Number((totalLsi / lsiValues.length).toFixed(2))
  avgLsi = isValidLSIRange(avgLsi) ? avgLsi : null

  return {
    minLsi: minLsi,
    avgLsi: avgLsi,
    maxLsi: maxLsi
  }
}
