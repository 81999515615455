import React from "react"
import { storage, token, isUserLoaded } from 'common/helpers/storage'
import api from 'common/helpers/api'
import config from 'app/config'
import { fetchDeviceActive } from "common/helpers/device";
import { connect } from "common/helpers/websocket"

const _authenticate = () => {
    // locked paths when authenticated
    let authLocked = ['/login', '/']
    // unlocked paths when unauthenticated
    const authUnlocked = ['/login', '/', '/registration']
    const isAuthed = token()
    return fetchDeviceActive()
    .then(res => {
        if (res) {
            authLocked = [...authLocked, '/registration']
            if (isAuthed && authLocked.includes(window.location.pathname)) {
                window.location.pathname = '/cortex'
            }
            else if (!isAuthed && !authUnlocked.includes(window.location.pathname)) {
                window.location.pathname = '/'
            }
        }
    })
    .catch(e => console.error('auth errors:', e))

}


const _login = credentials => {
    api.configure(token())
    const data = credentials
    return api.post(`${config.API_URL}/auth/`, data)
    .then(res => {
        sessionStorage.setItem('lmbk', JSON.stringify(res.data))
        return(res)
    })
}

const _logout = () => {
    sessionStorage.removeItem('lmbk')
    window.location.pathname = '/'
}

const _register = async registrationData => {
    const data = registrationData
    return await api.post(`${config.API_URL}/signup/member/`, data)
    .then(res => {
        const credentials = {username_or_email: data.email, password: data.password }
        return api.post(`${config.API_URL}/auth/`, credentials)
        .then(loginRes => {
            sessionStorage.setItem('lmbk', JSON.stringify(loginRes.data))
            if (token()) {
                api.configure(token())
                return true
            }
        })
    })
}

const _getUserData = (user_id) => {
    return api.get(`${config.API_URL}/user/${user_id}`)
} 

export const resendActivationEmail = () => {
    if(isUserLoaded()){
        let user_id = JSON.parse(sessionStorage.getItem('lmbk')).user_id
        return api.get(`${config.API_URL}/resend/${user_id}`)
    }else{
        return false
    }
}

export const isEmailActivated = () => {
    if(isUserLoaded()){
        let user_id = JSON.parse(sessionStorage.getItem('lmbk')).user_id
        return _getUserData(user_id)
    }else{
        return Promise.resolve({data: { user_settings: {email_activated: false}}})
    }

}

export const watchServer = (onmessage) => {
    if(isUserLoaded()){
        let user_id = JSON.parse(sessionStorage.getItem('lmbk')).user_id
        return connect(`${config.WS_URL}/ws/users/${user_id}/`, onmessage)
    }else{
        return "Error: User not loaded"
    }
}


export const checkAuth = () => {
    return _authenticate()
}

export const isAuthenticated = () => {
    let storedData = storage()
    console.log("here is is authenticated",storedData, token())
    return (storedData && token())
}

export const processLogin = async credentials => {
    return await _login(credentials)
}

export const processRegister = registrationData => {
    return _register(registrationData)

}

export const processLogout = () => {
    _logout()
}
